.heading {
  display: flex;
  flex-direction: row;
  /* grid-template-columns: repeat(2, 1fr); */

  height: 100vh;
}
.login-img {
  background: url("../../assets//img/profiles/unnamed.png");
  background-repeat: inherit;
}

.heading__lottie {
  padding: 40px 40px 40px 80px;
}

.heading__lottie h1 {
  font-size: 36px;
  font-family: "Poppins";
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.heading__lottie p {
  padding: 0;
  margin: 0;
}

/* .content1 {
  margin: 0 auto;
  max-width: 450px;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 19px;
  padding: 40px 50px;
} */
/* .content1 label {
  text-align: left;
  display: block;
  font-weight: bold;
  font-family: "Poppins";
} */

/* .content1 input {
  width: 100%;
  height: 50px;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
} */
.content1 button {
  background: #18838d;
  color: #fff;
  font-size: 14px;

  width: 150px;
  border: 0;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

/* @media screen and (max-width: 1350px) {
  .content1 {
    margin-right: 56px;
  }
}

@media screen and (max-width: 1050px) {
  .heading__lottie {
    height: 500px;
  }
}

@media screen and (max-width: 950px) {
  .heading {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .heading__lottie {
    margin: 20px;
    height: 50vh;
    margin-bottom: 100px;
  }

  .content1 {
    margin: 30px;
    justify-content: center;
    align-items: center;
  }
  .content1 h1 {
    font-size: 20px;
  }
} */
