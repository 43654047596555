.Button {
  width: 100%;
  padding: 10px;
  display: block;
  text-align: center;
  border: 4px solid rgb(72, 65, 100);
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 10px;
  background: linear-gradient(to bottom, #f2ea11 49%, #f7b503 50%);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
  font-size: 20px;
  transition: 0.3s;
  font-family: Kodchasan;
}

.Button:focus {
  outline: none;
}

.Button:disabled {
  color: initial;
  filter: grayscale(1);
}
