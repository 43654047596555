.ring-game {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: #82ccdd;
  margin: 0;
}

.App__container {
  max-width: 320px;
  margin: 0 auto;
  padding: 0 10px;
}

.App__heading {
  text-align: center;
  font-size: 35px;
  margin: 0 0 20px;
  font-weight: 700;
  font-family: Kodchasan;
}