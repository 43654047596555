img {
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  /* IE 9 */
  -webkit-transform: translateZ(0);
  /* Chrome, Safari, Opera */
  transform: translateZ(0);
}

.MessageContainer {
  margin-top: 8px;
  height: 230px;
}

.Message {
  display: flex;
}

.sent {
  flex-direction: row-reverse;
}

.sent h5 {
  color: white;
  background-color: #1f8ded;
  padding: 8px;
  border-radius: 12px;
  border-top-right-radius: 0px;
}

.received h5 {
  color: white;
  background-color: #ed9d44;
  padding: 8px;
  border-radius: 12px;
  border-top-left-radius: 0px;
}