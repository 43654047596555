.Chest {
  width: calc(300px / 6 - 10px);
  height: calc(300px / 6 - 10px);
  margin: 5px;
  cursor: pointer;
  background: url('../../../assets/chest.png') no-repeat -3px 50% / cover;
}

.Chest--open {
  cursor: default;
  background-position: -56px 50%;
  filter: grayscale(1);
}

.Chest--has-ring {
  position: relative;
  background-color: #F2EA11;
  filter: grayscale(0);
}

.Chest--has-ring::after {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translate(-50%);
  background: url('../../../assets/ring.gif') no-repeat center / cover;
}