.game {
    width: 240px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
}

.game img {
    width: 30px;
    height: 30px;
}

.board {
    width: 351px;
    height: 350px;
    background-color: #DC7633;
    border: 12px solid #1B4F72;
    display: flex;
    flex-direction: column;
}

.row {
    flex: 33%;
    display: flex;
    flex-direction: row;
}

.square {
    flex: 33%;
    height: 100%;
    border: 3px solid #1B4F72;
    cursor: pointer;
    display: grid;
    place-items: center;
    font-size: 30px;
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
}

.square:active {
    background-color: #DC7633;
    border: 3px solid #1B4F72;
}
