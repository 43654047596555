.login-img {
    background: url("../../assets/img/group-people-working-out-business-plan-office.jpg");
    /* transform: scale(1); */
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content1 {
    /* margin: 0 auto; */
    /* max-width: 450px; */
    text-align: center;
    /* border: 1px solid #d3d3d3;
    border-radius: 19px; */
    /* padding: 40px 50px; */
  }
  .login-form {
    background-color: white;
    margin-left: 12px !important;
    /* min-width: 450px; */
    /* width: 30% !important; */
  }
  /* .main-container { */
    /* margin-right: 0 !important; */
    /* display: flex; */
  /* } */
  .oz-logo {
    width: 180px;
    height: auto;
    margin-top: 12%;
    margin-bottom: 5%;
  }
  .content1 label {
    text-align: left;
    position: absolute;
    /* margin-left: 50px; */
    display: block;
    /* font-weight: bold; */
    font-family: sans-serif, "Open Sans";
  }
  .login-img {
    display: block;
    /* width: 70% !important; */
  }
  .login-input {
    width: 80%;
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .login-text {
    /* position: absolute; */
    /* margin-left: 50px; */
    display: flex;
    /* align-items: center;
    justify-content: center; */
  
    font-family: sans-serif, "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
  }
  
  @media only screen and (max-width: 1400px) {
    .login-img {
      /* display: none !important; */
      width: 60% !important;
    }
    .login-form {
      width: 40% !important;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .login-img {
      /* display: none !important; */
      width: 60% !important;
    }
    .login-form {
      width: 40% !important;
    }
  }
  @media only screen and (max-width: 800px) {
    .login-img {
      /* display: none !important; */
      width: 60% !important;
    }
    .login-form {
      width: 40% !important;
    }
  }
  @media only screen and (max-width: 771px) {
    .login-img {
      /* display: none !important; */
      width: 50% !important;
    }
    .login-form {
      width: 50% !important;
      height: 100vh !important;
    }
  }
  
  @media only screen and (max-width: 771px) {
    .login-img {
      display: none !important;
    }
    .login-form {
      width: 100% !important;
    }
  }
  
  .login-con {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-left: 20px;
    margin-right: 20px;
  }
  