.GameStatusCaption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  background: linear-gradient(to bottom, rgba(222, 222, 222, 0.8) 49%, rgba(184, 184, 184, 0.8) 50%);
  border: 4px solid rgba(109, 132, 194, 0.8);
  border-radius: 25px;
  padding: 10px;
  font-weight: 700;
}

.GameStatusCaption__text {
  margin: 0;
  font-size: 18px;
  white-space: nowrap;
  font-family: Kodchasan;
}