.ControlPanel__text {
  text-align: center;
  margin: 0 0 25px;
  font-size: 18px;
  font-family: Kodchasan;
  font-weight: 700;
}

.ControlPanel__text--danger {
  color: #EA2027;
}