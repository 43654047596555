/*
Author       : Dreamguys
Template Name: SmartHR - Bootstrap Admin Template
Version      : 3.6
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Helper Class
4. Bootstrap Classes
5. Animation
6. Toggle Button
7. Modal
8. Header
9. Sidebar
10. Content
11. Login
12. Dashboard
13. Activity
14. Select2
15. Nav tabs
16. Holidays
17. Delete Modal
18. Edit Profile
19. Chat
20. Focus Label
21. Leave
22. Employee
23. Events
24. Profile
25. Notifications
26. Roles & Permissions
27. Chat Right Sidebar
28. Projects
29. Invoice
30. Task
31. Project View
32. Payslip
33. Attendance
34. Ticket
35. Client Profile
36. Inbox
37. Mail View
38. Voice call
39. Video Call
40. Outgoing call
41. Incoming call
42. Contacts
43. Chat Sidebar
44. Jobs
45. Job Details
46. Notification Settings
47. Leave Settings
48. Termination
49. Loader
50. Payroll Items
51. Error
52. Lock Screen
53. OTP
54. Salary Settings
55. Components
56. Search
57. Knowledgebase
58. FAQ
59. Employee Dashboard
60. Performance Review
61. Kanban Board
62. File Manager
63. Subscriptions
64. Responsive
65. happyzone
========================================*/
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/MaterialIcons-Regular.woff2) format("woff2"),
    url(../fonts/MaterialIcons-Regular.woff) format("woff"),
    url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 400;
  /* src: local("Circular Std Book"),
    url("../fonts/CircularStd-Book.woff") format("woff"); */
}
@font-face {
  font-family: "Kodchasan", sans-serif;
  font-style: normal;
  font-weight: 400;
  /* src: local("Circular Std Book"),
    url("../fonts/CircularStd-Book.woff") format("woff"); */
}

@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: italic;
  font-weight: 400;
  /* src: local("Circular Std Book Italic"),
    url("../fonts/CircularStd-BookItalic.woff") format("woff"); */
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 500;
  /* src: local("Circular Std Medium"),
    url("../fonts/CircularStd-Medium.woff") format("woff"); */
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: italic;
  font-weight: 500;
  /* src: local("Circular Std Medium Italic"),
    url("../fonts/CircularStd-MediumItalic.woff") format("woff"); */
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 600;
  /* src: local("Circular Std Bold"),
    url("../fonts/CircularStd-Bold.woff") format("woff"); */
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: italic;
  font-weight: 600;
  /* src: local("Circular Std Bold Italic"),
    url("../fonts/CircularStd-BoldItalic.woff") format("woff"); */
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: normal;
  font-weight: 800;
  /* src: local("Circular Std Black"),
    url("../fonts/CircularStd-Black.woff") format("woff"); */
}
@font-face {
  font-family: sans-serif, "Open Sans";
  font-style: italic;
  font-weight: 800;
  /* src: local("Circular Std Black Italic"),
    url("../fonts/CircularStd-BlackItalic.woff") format("woff"); */
}

/*-----------------
	1. General
-----------------------*/
#root {
  /* overflow-x: hidden;
  overflow-y: auto;
  height: 100vh; */
  /* backdrop-filter: blur(5px); */
}
/* .App{
  overflow: auto;
} */
/* .page-wrapper {
  overflow: scroll;
} */
html {
  height: 100%;
}
.App {
  /* backdrop-filter: blur(50px); */
  /* background-image: url(../img/profiles/back.jpg); */
  font-size: 14px;
  color: #1f1f1f;
  background-position: center;
  height: 100%;
  background-size: cover;
  background-color: #1e2442;
}
.App1 {
  /* backdrop-filter: blur(50px); */
  /*background-image: url(../img/profiles/back.jpg);*/
  font-size: 14px;
  color: #1f1f1f;
  background-position: center;
  height: 100%;
  background-size: cover;
  background-color: #1e2442;
}

/* .App:before {
  content: "";
  background: rgba(5, 13, 24, 0.3);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
} */

body {
  font-family: sans-serif, "Open Sans";

  background-color: #f6f5f5;
  /* filter: blur(8px); */

  /* -webkit-filter: blur(8px);  */
  height: 100%;
  overflow: hidden;

  /* background-repeat: no-repeat; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  margin-top: 0;
  font-weight: 500;
  color: #344767;
  /* font-size: 20px; */
}
h1,
.h1 {
  font-size: 2.25rem;
}
h2,
.h2 {
  font-size: 1.875rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 0.875rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}
a {
  text-decoration: none;
}
.form-control {
  border-color: #e3e3e3;
  box-shadow: none;
  font-size: 15px;
  height: 34px;
}
thead.card-board {
  display: none !important;
}
.form-control:focus {
  border: 2px solid #2684ff;
  box-shadow: none;
  outline: 0 none;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
input,
input:focus,
button,
button:focus {
  outline: none;
}
input[type="file"] {
  height: 33px;
}
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea.form-control {
  resize: vertical;
}
.navbar-nav > li {
  float: left;
}
.form-control-lg.form-control {
  border-radius: 4px;
  height: 46px;
}
.form-control-sm.form-control {
  height: 30px;
  border-radius: 0;
}
.input-group.input-group-lg .form-control {
  height: 46px;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.input-group-text {
  background-color: #f7f7f7;
  border-color: #e3e3e3;
  height: 100%;
}
.component .bar-icon {
  display: none;
}
.select2-dropdown {
  z-index: 1055;
}
select.form-control[multiple] option,
select.form-control[size] option {
  border-radius: 3px;
  padding: 7px 12px;
}
select.form-control[multiple],
select.form-control[size] {
  height: 200px;
  padding: 7px;
}
.form-group {
  margin-bottom: 1rem;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.pass-group {
  position: relative !important;
}
span.fa.toggle-password\".fa-eye {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
  cursor: pointer;
}
span.fa.toggle-password\".fa-eye-slash {
  position: absolute !important;
  right: 17px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #757575 !important;
  cursor: pointer !important;
}
form small {
  width: 100%;
  margin-top: 0.25rem !important;
  font-size: unset !important;
  color: #f62d51 !important;
  display: inline-block !important;
}
.error-input {
  border-color: #dc3545 !important;
}

/*-----------------
	2. Table
-----------------------*/

.table-responsive {
  white-space: nowrap;
  width: 100%;
}
.table {
  color: #333;
}
.table.table-white {
  background-color: #fff;
}
.table thead th {
  font-weight: 500;
  letter-spacing: 0.05em;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.ant-table-column-title {
  z-index: 0 !important;
}
table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.avatar .image1 {
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
}
.avatar img {
  border-radius: 50% !important;
  height: 39px;
  width: 39px;
  margin-top: -0.5px;
}
table.table td h2.table-avatar.blue-link a {
  color: #007bff;
}
table.table td h2 a {
  color: #333;
}
table.table td h2 a:hover {
  color: #ff9b44;
}
table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px;
}
table.dataTable {
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table-hover tbody tr:hover {
  background-color: #f7f7f7;
  color: #212529;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px !important;
}

/*-----------------
	3. Helper Class
-----------------------*/

.p-20 {
  padding: 20px !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.w-40 {
  width: 40px;
}
.btn-md {
  height: 40px;
}
.block {
  display: block !important;
}
.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-muted-light {
  color: #aaa;
}
.text-xs {
  font-size: 0.75rem !important;
}
.text-sm {
  font-size: 0.875rem !important;
}
.text-lg {
  font-size: 1.25rem !important;
}
.text-xl {
  font-size: 1.5rem !important;
}
.font-18 {
  font-size: 18px;
}
.btn.btn-rounded {
  border-radius: 50px;
}

/*-----------------
	4. Bootstrap Classes
-----------------------*/

.btn {
  border-radius: 5px;
}
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}
.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}
.btn-white:focus,
.btn-white:hover {
  background-color: #f7f7f7;
}
.rounded,
.circle {
  border-radius: 500px;
}
.bg-purple,
.badge-purple {
  background-color: #7460ee !important;
}
.bg-primary,
.badge-primary {
  background-color: #ff9b44 !important;
}
.bg-success,
.badge-success {
  background-color: #55ce63 !important;
}
.bg-info,
.badge-info {
  background-color: #009efb !important;
}
.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}
.bg-danger,
.badge-danger {
  background-color: #f62d51 !important;
}
.bg-white {
  background-color: #fff;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
  color: #ff9b44 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
  color: #55ce63 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
  color: #f62d51 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
  color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
  color: #7460ee !important;
}
.text-muted {
  color: #8e8e8e !important;
}
.btn-purple {
  background-color: #7460ee;
  border: 1px solid #7460ee;
  color: #fff;
}
.btn-purple:hover,
.btn-purple:focus {
  background-color: #482fe9;
  border: 1px solid #482fe9;
}
.btn-primary {
  background-color: #ff9b44;
  border: 1px solid #ff9b44;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #ff851a;
  border: 1px solid #ff851a;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #ff851a;
  border: 1px solid #ff851a;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #ff851a;
  border-color: #ff851a;
  color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #fff;
}
.btn-success {
  background-color: #18838d;
  border: 1px solid #18838d;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background: #4ab657;
  border: 1px solid #4ab657;
  color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  background: #4ab657;
  border: 1px solid #4ab657;
}
.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #55ce63;
  border-color: #55ce63;
  color: #fff;
}
.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}
.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}
.btn-warning {
  background: #ffbc34;
  border: 1px solid #ffbc34;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}
.btn-danger {
  background: #f62d51;
  border: 1px solid #f62d51;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #e6294b;
  border: 1px solid #e6294b;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background: #e9ab2e;
  border: 1px solid #e9ab2e;
}
.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}
.btn-custom {
  background: #ff9b44;
  background: -moz-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -webkit-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -ms-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  color: #fff;
}
.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
  background: #ff9b44;
  background: -moz-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -webkit-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -ms-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  color: #fff;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #ff9b44;
  border-color: #ff9b44;
}
.pagination > li > a,
.pagination > li > span {
  color: #ff9b44;
}
/* .page-link:hover {
  color: #ff9b44;
} */
.page-link:focus {
  box-shadow: unset;
}
.page-item.active .page-link {
  background-color: #ff9b44;
  border-color: #ff9b44;
}
.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
}
.navbar-nav .open .dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.dropdown-menu {
  font-size: 13px;
  z-index: 1000 !important;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  box-shadow: none;
}
.btn-link {
  color: #333;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.form-text {
  color: #999;
  font-size: 12px;
  margin-bottom: 7px;
  margin-top: 7px;
}
.btn.focus,
.btn:focus {
  box-shadow: unset;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #ff9b44;
}
.list-group-item {
  border: 1px solid #e3e3e3;
}
.custom-file {
  height: 44px;
}
.custom-file .custom-file-label {
  border: 1px solid #e3e3e3;
  height: 44px;
  margin-bottom: 0;
  padding: 10px 15px;
}
.custom-file-label::after {
  background-color: #f2f2f2;
  height: 42px;
  padding: 10px 15px;
}
.custom-file input[type="file"] {
  height: 44px;
}
.bg-inverse-primary {
  background: rgba(77, 138, 240, 0.2) !important;
  color: #4d8af0 !important;
}
.bg-inverse-secondary {
  background: rgba(194, 198, 209, 0.2) !important;
  color: #c2c6d1 !important;
}
.bg-inverse-success {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}
.bg-inverse-purple {
  background: rgba(108, 97, 246, 0.2) !important;
  color: #6c61f6 !important;
}
.bg-inverse-warning {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}
.bg-inverse-danger {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}
.bg-inverse-light {
  background: rgba(223, 223, 223, 0.2) !important;
  color: #dfdfdf !important;
}
.bg-inverse-dark {
  background: rgba(116, 118, 123, 0.2) !important;
  color: #74767b !important;
}
.bg-inverse-white {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #fff !important;
}
.bg-inverse-info {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}
.bg-inverse-default {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}
.bg-gradient-primary {
  background: linear-gradient(to right, #6e00ff 10%, #6e00ff 100%);
}
.bg-gradient-secondary {
  background: linear-gradient(to bottom right, #f35c38 0, #f35c38 100%);
}
.bg-gradient-success {
  background: linear-gradient(to bottom right, #0ba408 0, #28e224 100%);
}
.bg-gradient-info {
  background: linear-gradient(to bottom right, #69c7de 0, #1e9fc4 100%);
}
.bg-gradient-warning {
  background: linear-gradient(to bottom right, #ecd53e 0, #efaf28 100%);
}
.bg-gradient-teal {
  background: linear-gradient(to bottom right, #00796b 0, #4db6ac 100%);
}
.bg-gradient-blue {
  background: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}
.bg-gradient-danger {
  background: linear-gradient(to right, #f95058 0, #f33540 100%);
}
.bg-gradient-purple {
  background: linear-gradient(to right, #8a56e6 0, #6f3cca 100%);
}

/*-----------------
	5. Animation
-----------------------*/

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes taskHighlighter {
  0% {
    background: #feeb99;
  }
  100% {
    background: #fff;
  }
}
@keyframes taskHighlighter {
  0% {
    background: #feeb99;
  }
  100% {
    background: #fff;
  }
}

/*-----------------
	6. Toggle Button
-----------------------*/

.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.checktoggle {
  background-color: #c4c4c4;
  border-radius: 12px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 48px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(5px, -50%);
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
  background-color: #4531b3;
}
.check:checked + .checktoggle:after {
  left: 100%;
  transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
  margin-left: auto;
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 0;
}
.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  box-sizing: border-box;
  color: #fff;
  display: block;
  float: left;
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  width: 50%;
}
.onoffswitch-inner:before {
  background-color: #55ce63;
  color: #fff;
  content: "ON";
  padding-left: 14px;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 14px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.onoffswitch-switch {
  background: #fff;
  border-radius: 20px;
  bottom: 0;
  display: block;
  height: 20px;
  margin: 5px;
  position: absolute;
  right: 43px;
  top: 0;
  transition: all 0.3s ease-in 0s;
  width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.switch {
  cursor: pointer;
  position: relative;
}
.switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.switch input:checked + span {
  background-color: #55ce63;
}
.switch input:checked + span:after {
  left: 31px;
}
.switch span {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.switch span:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 26px;
  top: 1px;
  bottom: 1px;
  border-radius: 30px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
}
.rating-list {
  display: flex;
  justify-content: center;
}
.rating-list > span {
  display: inline-block;
  margin-right: 4px;
}
.rating-list span a {
  background-color: #ccc;
  border-radius: 4px;
  color: #333;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.rating-list span.rating-bad a {
  background-color: #ad0d20;
  color: #fff;
}
.rating-list span.rating-normal a {
  background-color: #dccc00;
  color: #fff;
}
.rating-list span.rating-good a {
  background-color: #00a100;
  color: #fff;
}
.rating-text {
  font-size: 12px;
  max-width: 157px;
  display: inline-block;
  margin-top: 5px;
}
.rating-list > span.rating-normal {
  text-align: center;
}
.rating-list > span.rating-good {
  text-align: right;
}
.custom_check {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_check input:checked ~ .checkmark {
  background-color: #ff9b44;
  border: 1px solid transparent;
}
.custom_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  background-color: #fff;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom_check input:checked ~ .checkmark:after {
  display: block;
}
.custom_radio {
  color: #555;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio_input .custom_radio + .custom_radio {
  margin-left: 15px;
}
.custom_radio input {
  position: absolute;
  opacity: 0;
}
.custom_radio input:checked ~ .checkmark:after {
  opacity: 1;
}
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.custom_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff9b44;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.setting-performance-table.table > tbody > tr > td,
.setting-performance-table.table > tbody > tr > th,
.setting-performance-table.table > tfoot > tr > td,
.setting-performance-table.table > tfoot > tr > th,
.setting-performance-table.table > thead > tr > td,
.setting-performance-table.table > thead > tr > th {
  vertical-align: middle;
}

.setting-performance-table textarea.form-control {
  resize: none;
  height: 44px !important;
  padding-top: 10px !important;
}
/*-----------------
	7. Modal
-----------------------*/

.modal {
  -webkit-overflow-scrolling: touch;
}
.modal-footer.text-center {
  justify-content: center;
}
.modal-footer.text-left {
  justify-content: flex-start;
}
.modal-dialog.modal-md {
  max-width: 500px;
}
.custom-modal .modal-content {
  border: 0;
  border-radius: 10px;
}
.custom-modal .modal-footer {
  border: 0;
}
.custom-modal .modal-header {
  border: 0;
  justify-content: center;
  padding: 0 !important;
}
.custom-modal .modal-footer {
  border: 0;
  justify-content: center;
  padding: 0 30px 30px;
}
.custom-modal .modal-body {
  padding: 30px;
}
.custom-modal .close {
  background-color: #d81d1d;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99;
}
.custom-modal .modal-title {
  font-size: 22px;
}
.modal-backdrop.show {
  opacity: 0.4;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
}
.modal .card {
  box-shadow: unset;
}
.modal-backdrop {
  opacity: 0.25 !important;
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
  /* position: fixed; */
  float: right;
  width: 50%;
  margin: auto;
  /* width: 320px; */
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
  padding: 15px 15px 80px;
}
.come-from-modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 5s linear, right 1s ease-out;
  -moz-transition: opacity 5s linear, right 1s ease-out;
  -o-transition: opacity 5s linear, right 1s ease-out;
  transition: opacity 5s linear, right 1s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
  right: 0;
}

/*-----------------
	8. Header
-----------------------*/

.header {
  /* background: #ffffff;
  opacity: 0.2; */
  background-color: rgba(255, 255, 255, 0.1);
  /* border-bottom: 1px solid #ededed; */
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
  height: 60px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1002;
}

.header.active {
  background: #353a55;
  box-shadow: 0 5px 5px 0 rgba(34, 6, 90, 0.2);
}

.header1 {
  /* background: #ffffff;
  opacity: 0.2; */
  background-color: rgb(166, 57, 57);
  /* border-bottom: 1px solid #ededed; */
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2); */
  height: 60px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1002;
}

/* notififation display */
.dropdown-menu.notifications.show {
  display: block;
}

.header .header-left {
  float: left;
  height: 60px;
  padding: 0 50px;
  position: relative;
  text-align: center;
  width: 230px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}

.bar-icon {
  display: inline-block;
  width: 21px;
}
.bar-icon span {
  background-color: #999;
  border-radius: 500px;
  display: block;
  float: left;
  height: 2px;
  margin-bottom: 5px;
  width: 21px;
}
.bar-icon span:nth-child(2) {
  width: 15px;
}
.bar-icon span:nth-child(3) {
  margin-bottom: 0;
}
.header .navbar-nav .badge {
  position: absolute;
  right: 7px;
  top: 4px;
}
.header .dropdown-menu > li > a {
  position: relative;
}
.header .dropdown-toggle::after {
  display: none;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.page-title-box {
  border-radius: 0;
  float: left;
  height: 60px;
  margin-bottom: 0;
  padding: 17px 20px;
}
.page-title-box h3 {
  color: #333;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}
.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}
.user-menu.nav > li > a {
  color: #333;
  font-size: 15px;
  line-height: 60px;
  padding: 0 15px;
  height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.flag-nav img {
  margin-right: 5px;
}
.flag-nav .dropdown-menu {
  max-height: 150px;
  min-width: 120px;
  overflow-y: auto;
}
.flag-nav .dropdown-menu .dropdown-item {
  padding: 5px 15px;
}
.user-img {
  display: inline-block;
  position: relative;
}
.main-drop .user-img img {
  border-radius: 50%;
  width: 30px;
}
.user-img .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  margin: 0;
  position: absolute;
  right: 0;
  width: 10px;
}
.user-menu .user-img .status {
  bottom: 12px;
}
.status {
  background-color: #263238;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}
.status.online {
  background-color: #55ce63;
}
.status.offline {
  background-color: #f62d51;
}
.status.away {
  background-color: #faa937;
}
.main-drop .dropdown-menu {
  min-width: 130px;
  padding: 0;
}
.css-7u4msn-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.css-we9vj2:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
/*-----------------
	9. Sidebar
-----------------------*/

.sidebar {
  background: rgba(17, 11, 44, 0.1);

  /* border-right: 1px solid #ddd; */
  bottom: 0;
  left: 0;
  margin-top: -60px;
  position: fixed;
  top: 120px;
  transition: all 0.2s ease-in-out 0s;
  width: 200px;
  z-index: 1001;
}
.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mini-sidebar .sidebar {
  width: 60px;
}
.sidebar-inner {
  height: 100%;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
  padding: 10px 0;
}
.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.sidebar-menu li a {
  color: #ffffff;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}
.sidebar-menu li a:hover {
  /* background-color: #ffebda; */
  background-color: rgba(226, 230, 232, 0.2);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.sidebar-menu li.active a {
  color: white;
  background-color: rgba(226, 230, 232, 0.2);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.menu-title {
  color: #f4f1f1;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
}
.menu-title > i {
  float: right;
  line-height: 40px;
}
.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}
.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
  background-color: #e5e5dc;
  color: #000;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.mobile-user-menu {
  color: #fff;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 10;
}
.mobile-user-menu > a {
  color: #333;
  padding: 0;
}
.mobile-user-menu a:hover {
  color: #333;
}
.mobile-user-menu > .dropdown-menu {
  min-width: 130px;
}
.mobile-user-menu > .dropdown-menu > a {
  line-height: 23px;
}
.profile-rightbar {
  display: none !important;
  color: #bbb;
  font-size: 26px;
  margin-left: 15px;
}
.mobile_btn {
  display: none;
  float: left;
}

.logout-btn-mob {
  background: transparent;
  border: none;
  display: none;
}
.logout-btn {
  display: none;
}

.logout-btn {
  background: #ed9d44;
  color: white !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 9px;

  /* position: absolute; */
  height: 38px;
  width: 116px;
  margin-top: 10px;
  margin-right: 50px;
  border-radius: 50px;
  padding: 8px 10px 8px 10px;
  border: none;
}

.happy-zone {
  background: #ed9d44;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  float: right;
  position: relative;
  height: 40px;
  width: 165px;
  max-width: 170px;
  margin-top: 8px;
  margin-right: 10px;
  border-radius: 8px;
  border: none;
}

.sidebar .sidebar-menu > ul > li > a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 15px;
  white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto;
}
.sidebar-menu ul ul a {
  display: block;
  font-size: 14px;
  padding: 9px 10px 9px 50px;
  position: relative;
}
.sidebar-menu ul ul ul a {
  padding-left: 70px;
}
.sidebar-menu ul ul ul ul a {
  padding-left: 90px;
}
.sidebar-menu > ul > li {
  position: relative;
}
.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}
.sidebar-menu .menu-arrow:before {
  content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.noti-dot:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 5px solid #ff9b44;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #ff9b44;
  z-index: 10;
  position: absolute;
  right: 37px;
  top: 15px;
}
.noti-dot:after {
  content: "";
  border: 4px solid #ff9b44;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 24px;
  width: 24px;
  -webkit-animation: pulse 3s ease-out;
  -moz-animation: pulse 3s ease-out;
  animation: pulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: 8px;
  right: 30px;
  z-index: 1;
  opacity: 0;
}
.sidebar-menu ul ul a .menu-arrow {
  top: 6px;
}
.sidebar-menu a {
  line-height: 1.3;
  transition: unset;
  -moz-transition: unset;
  -o-transition: unset;
  -ms-transition: unset;
  -webkit-transition: unset;
}
.sidebar-menu > ul > li > a {
  padding: 8px 15px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}
.sidebar-menu li a > .badge {
  color: #fff;
}

/*-----------------
	10. Content
-----------------------*/

.page-wrapper {
  left: 0;
  margin-left: 230px;
  padding-top: 60px;
  position: relative;
  transition: all 0.2s ease-in-out;
  /* min-height: 528px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-wrapper > .content {
  padding: 30px;
}
.page-header {
  margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.page-header .breadcrumb a {
  color: #333;
}
.card {
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 30px;
}
.card1 {
  margin-bottom: 10px;
  border: none;
  border-radius: 30px !important;
  background: none;
}
.ant-pagination-item {
  border-radius: 50% !important;
  margin-left: 2px !important;
  height: " " !important;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  border-radius: 50% !important;
}
.ant-pagination-next.ant-pagination-jump-next {
  margin-left: 2px !important;
}
.ant-pagination-total-text {
  float: left !important;
}

.ant-pagination-item-active {
  font-weight: 500;
  border: none !important;
  /* background: linear-gradient(180deg, #27a8e2 23.5%, #4382eb 100%) !important; */
  background-color: #393f5c !important;
  /* border-color:  linear-gradient(180deg, #27A8E2 23.5%, #4382EB 100%) !important; */
  color: rgb(255, 255, 255) !important;
  z-index: 1 !important;
}
.card-title {
  color: #1f1f1f;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.card-header {
  background-color: #fff;
}
.card-footer {
  background-color: #fff;
}
.page-title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
}
.bell-icon {
  color: white;
}
.bell-icon.active {
  color: #000;
}

.page-title.active {
  color: white;
}
.page-sub-title {
  color: #565656;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}
.add-btn {
  background-color: #1f8ded;
  border: 1px solid #1f8ded;
  color: #fff;
  float: right;
  font-weight: 500;
  min-width: 140px;
}
/*
.add-btn:hover,
.add-btn:focus,
.add-btn:active {
  background-color: #ff851a;
  border: 1px solid #ff851a;
  color: #fff;
} */
.add-btn i {
  margin-right: 5px;
}
.cal-icon {
  position: relative;
  width: 100%;
}
.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}
.form-focus .cal-icon:after {
  top: 15px;
}

/*-----------------
	11. Login
-----------------------*/

.account-page {
  align-items: center;
  display: flex;
}
.account-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.account-content {
  padding: 20px 0;
}
.account-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
.account-subtitle {
  color: #4c4c4c;
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
}
.account-box {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  overflow: hidden;
  width: 480px;
}
.account-wrapper {
  padding: 30px;
}
.account-logo {
  margin-bottom: 30px;
  text-align: center;
}
.account-box .form-group {
  margin-bottom: 25px;
}
.account-box .account-btn {
  background: #ff9b44;
  background: -moz-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -webkit-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -ms-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  border: 0;
  border-radius: 4px;
  display: block;
  font-size: 22px;
  padding: 10px 26px;
  width: 100%;
}
.account-box .account-btn:hover,
.account-box .account-btn:focus {
  border: 0;
  opacity: 0.8;
}
.account-box .form-control {
  background-color: #fbfbfb;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  height: 46px;
}
.account-box label {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}
.account-logo img {
  width: 100px;
}
.account-footer {
  text-align: center;
}
.account-footer p {
  margin-bottom: 0;
}
.account-footer a {
  color: #ff9b44;
}
.account-footer a:hover {
  color: #ff9b44;
}

/*-----------------
	12. Dashboard
-----------------------*/

.card-table .card-header {
  background-color: #fff;

  border-bottom: 1px solid #ddd;
  padding: 15px;
}
.card-table .card-body {
  padding: 0;
}
.card-table .card-footer {
  background-color: #fff;
  text-align: center;
}
.card-table .card-footer a {
  color: #333;
}
.card-title > a.btn {
  color: #fff;
}
.custom-table tr {
  background-color: #fff;
  box-shadow: 0 0 3px #e5e5e5;
}
.table.custom-table > tbody > tr > td,
.table.custom-table > tbody > tr > th,
.table.custom-table > tfoot > tr > td,
.table.custom-table > tfoot > tr > th,
.table.custom-table > thead > tr > td,
.table.custom-table > thead > tr > th {
  padding: 10px 8px;
  vertical-align: middle;
}
.table.custom-table > tbody > tr > td:first-child,
.table.custom-table > thead > tr > th:first-child {
  padding-left: 15px;
}
.table.custom-table > tbody > tr > td:last-child,
.table.custom-table > thead > tr > th:last-child {
  padding-right: 15px;
}
.dash-widget-icon {
  background-color: rgba(255, 155, 68, 0.2);
  border-radius: 100%;
  color: #ff9b44;
  display: inline-block;
  float: left;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  text-align: center;
  width: 60px;
}
.dash-widget-info {
  text-align: right;
}
.dash-widget-info > h3 {
  font-size: 30px;
  font-weight: 600;
}
.dash-widget-info > span {
  font-size: 16px;
}
.dash-statistics .stats-info {
  margin-bottom: 5px;
}
.dash-statistics .stats-info:last-child {
  margin-bottom: 0;
}
.dash-statistics .progress {
  height: 4px;
}
.dash-statistics .stats-list {
  height: auto;
}
.leave-info-box {
  border: 1px solid #e5e5e5;
  padding: 15px;
  margin-bottom: 15px;
}
.leave-info-box:last-child {
  margin-bottom: 0;
}
.load-more a {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: inline-block;
  font-size: 14px;
  padding: 5px 15px;
}

/*-----------------
	13. Activity
-----------------------*/

.activity-box {
  position: relative;
}
.activity-list {
  list-style: none;
  margin: 0 0 0 10px;
  padding: 0;
  position: relative;
}
.activity .activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.activity .activity-list > li .activity-user {
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 32px;
}
.activity .activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 0 40px;
  padding: 0;
  position: relative;
}
.activity-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 23px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.activity .activity-list li::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 22px;
  position: absolute;
  top: 12px;
  width: 2px;
}
.activity-list li::before {
  background: #eee;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.activity-list > li {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  border: 1px solid #ededed;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.activity-list > li:last-child .activity-content {
  margin-bottom: 0;
}
.activity-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}
.activity-list > li .activity-user {
  background: #fff;
  height: 32px;
  left: -7px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 3px;
  width: 32px;
}
.activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}
.activity-list > li .activity-content .timeline-content {
  color: #9e9e9e;
  background-color: none !important;
}
.activity-content .timeline-content {
  background-color: none !important;
}

.activity-list > li .activity-content .timeline-content a {
  color: #616161;
}
.activity-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 13px;
}

/*-----------------
	14. Select2
-----------------------*/

.select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  height: 44px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 42px;
  right: 7px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 15px;
  font-weight: normal;
  line-height: 42px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #ff9b44;
}

/*-----------------
	15. Nav Tabs
-----------------------*/

.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #ff9b44;
  border-color: #ff9b44;
  color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}
.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.tab-content {
  padding-top: 20px;
}

/*-----------------
	16. Holidays
-----------------------*/

.custom-table tr.holiday-completed {
  color: #aaa;
}
.dropdown.action-label {
  display: inline-block;
}
.action-label > a {
  display: inline-block;
  min-width: 103px;
}
.action-label .btn-sm {
  padding: 4px;
}

/*-----------------
	17. Delete Modal
-----------------------*/

.form-header {
  text-align: center;
  margin-bottom: 30px;
}
.form-header h3 {
  color: #333;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.form-header p {
  color: #929292;
  font-size: 15px;
  margin-bottom: 0;
}
.modal-btn > .row {
  margin-left: -8px;
  margin-right: -8px;
}
.modal-btn > .row > div {
  padding-left: 8px;
  padding-right: 8px;
}
.continue-btn {
  background-color: #fff;
  border: 1px solid #ff9b44;
  border-radius: 50px;
  color: #ff9b44;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
}
.continue-btn:hover,
.continue-btn:focus,
.continue-btn:active {
  background-color: #ff9b44;
  border: 1px solid #ff9b44;
  color: #fff;
}
.cancel-btn {
  background-color: #fff;
  border: 1px solid #ff9b44;
  border-radius: 50px;
  color: #ff9b44;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
}
.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
  background-color: #ff9b44;
  border: 1px solid #ff9b44;
  color: #fff;
}
span#close-task-panel {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*-----------------
	18. Edit Profile
-----------------------*/

.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: transparent;
  margin-top: 10px;
  /* overflow: hidden; */
}
.profile-basic {
  margin-left: 140px;
}
.modal-backdrop.fade.show {
  display: none;
}
.profile-basic .cal-icon {
  width: 100%;
}
.profile-img-wrap img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}
.profile-img-wrap button {
  position: absolute;
  right: 0;
  bottom: 0;
  background: none;
  border-radius: 0;
  padding: 3px 10px;
  border: none;
  height: 40px !important;
  width: 40px !important;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}
.btn-text {
  color: #fff;
}
.submit-section {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  /* background-color: white; */

  /* border-top: 1px solid black; */

  /* margin-left: 25px; */
}
.submit-section1 {
  text-align: center;
  margin-top: auto;
  display: flex;
  background-color: white;
  padding-bottom: 20px;
  /* border-top: 1px solid black; */
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  /* margin-left: 25px; */
  z-index: 100000 !important;
}
.btn-sub {
  border: 1px solid #3298db;
  border-radius: 5px;
  /* color: white; */
  margin-left: 25px;
  padding: 6px 12px;
}
.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}
.form-scroll {
  max-height: 400px;
  overflow-y: auto;
}
.profile-img-wrap.edit-img {
  border-radius: 50%;
  margin: 0 auto 30px;
  position: relative;
}
.profile-img-wrap.edit-img .fileupload.btn {
  left: 0;
}

/*-----------------
	19. Chat
-----------------------*/

.chat-avatar-sm {
  display: inline-block;
  float: left;
  margin-left: 0 !important;
  margin-right: 10px;
  position: relative;
  width: 24px;
}
.chat-avatar-sm img {
  width: 24px;
}
.chat-avatar-sm.user-img .status {
  bottom: 0;
  right: -3px;
}
.chat-main-row {
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  right: 0;
  top: 0;
}
.chat-main-wrapper {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}
.message-view {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  width: 75%;
}
.chat-window {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  background-color: #f7f7f7;
}
.fixed-header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}
.fixed-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
  padding: 0;
}
.fixed-header .user-info a {
  color: #555;
  text-transform: uppercase;
}
.typing-text {
  color: #ff9b44;
  font-size: 12px;
  text-transform: lowercase;
}
.last-seen {
  color: #888;
  display: block;
  font-size: 12px;
}
.custom-menu {
  margin-top: 6px;
}
.fixed-header .custom-menu {
  margin: 0 0 1px;
}
.custom-menu.nav > li > a {
  color: #bbb;
  font-size: 26px;
  line-height: 32px;
  margin-left: 15px;
  padding: 0;
}
.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
  background-color: transparent;
}
.custom-menu .dropdown-menu {
  left: auto;
  right: 0;
}
.search-box {
  margin-top: 5px;
}
.search-box .input-group {
  background-color: #f7f7f7;
  border: 1px solid #e3e3e3;
  border-radius: 50px;
}
.search-box .input-group .form-control {
  background: transparent;
  border: none;
}
.search-box .btn {
  background-color: transparent;
}
.search-input {
  border-radius: 5px;
}
.chat-contents {
  display: table-row;
  height: 100%;
}
.chat-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}
.chat-wrap-inner {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.chats {
  padding: 30px 15px;
}
.chat-body {
  display: block;
  margin: 10px 0 0;
  overflow: hidden;
}
.chat-body:first-child {
  margin-top: 0;
}
.chat-right .chat-content {
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  border-radius: 20px 2px 2px 20px;
  clear: both;
  color: #727272;
  display: block;
  float: right;
  margin: 0;
  max-width: 60%;
  padding: 8px 15px;
  position: relative;
}
.chat-right .chat-body {
  padding-left: 48px;
  padding-right: 10px;
}
.chat-bubble {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.chat-bubble:hover .chat-action-btns {
  display: block;
  float: left;
}
.chat-right .chat-bubble:hover .chat-action-btns {
  float: right;
  display: block;
}
.chat.chat-right .chat-bubble:last-child .chat-content {
  border-bottom-right-radius: 20px;
}
.chat.chat-right .chat-bubble:first-child .chat-content {
  border-top-right-radius: 20px;
}
.chat-content > p {
  margin-bottom: 0;
}
.chat-time {
  color: rgba(0, 0, 0, 0.5);
  display: block;
  font-size: 12px;
}
.chat-right .chat-time {
  text-align: right;
}
.chat-bubble .chat-action-btns {
  display: none;
}
.chat-action-btns {
  float: right;
}
.chat-action-btns ul {
  list-style: none;
  padding: 0;
  margin: 15px 15px 0 15px;
}
.chat-action-btns ul > li {
  display: inline-block;
  margin-left: 5px;
  font-size: 18px;
}
.chat-right .chat-action-btns {
  float: left;
}
.chat-bubble .chat-action-btns a {
  color: #8c8c8c;
}
.chat-line {
  border-bottom: 1px solid #eaeaea;
  height: 12px;
  margin: 7px 0 20px;
  position: relative;
  text-align: center;
}
.chat-date {
  background-color: #f7f7f7;
  color: #727272;
  font-size: 12px;
  padding: 0 11px;
}
.chat-avatar {
  float: right;
}
.chat-avatar .avatar {
  line-height: 30px;
  height: 30px;
  width: 30px;
}
.chat-left .chat-avatar {
  float: left;
}
.chat-left .chat-body {
  margin-right: 0;
  margin-left: 30px;
  padding-right: 20px;
}
.chat-left .chat-content {
  background-color: #fff;
  border: 1px solid #eaeaea;
  color: #888;
  float: left;
  position: relative;
  padding: 8px 15px;
  border-radius: 2px 20px 20px 2px;
  max-width: 60%;
}
.avatar {
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}
.avatar:hover {
  color: #fff;
}
.avatar.avatar-xs {
  width: 24px;
  height: 24px;
}
.avatar > img {
  border-radius: 50%;
  display: block;
  overflow: hidden;
  width: 100%;
}
.chat.chat-left .chat-bubble:first-child .chat-content {
  border-top-left-radius: 20px;
  margin-top: 0;
}
.chat.chat-left .chat-bubble:last-child .chat-content {
  border-bottom-left-radius: 20px;
}
.chat-left .chat-time {
  color: #a9a9a9;
}
.attach-list {
  color: #a3a3a3;
  padding: 5px 0 0;
  line-height: 24px;
  margin: 0;
  list-style: none;
}
.attach-list i {
  margin-right: 3px;
  font-size: 16px;
}
.chat-footer {
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  padding: 15px;
}
.message-bar {
  display: table;
  height: 44px;
  position: relative;
  width: 100%;
}
.message-bar .message-inner {
  display: table-row;
  height: 100%;
  padding: 0 8px;
  width: 100%;
}
.message-bar .link {
  color: #777;
  display: table-cell;
  font-size: 20px;
  padding: 0 10px;
  position: relative;
  vertical-align: middle;
  width: 30px;
}
.message-bar .message-area {
  display: table-cell;
}
.message-area .input-group .form-control {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: none;
  color: #555;
  display: block;
  font-size: 14px;
  height: 44px;
  margin: 0;
  padding: 6px 12px;
  resize: none;
}
.message-area .btn {
  height: 44px;
  width: 50px;
}
.profile-right {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
}
.display-table {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
.table-row {
  display: table-row;
  height: 100%;
}
.table-body {
  position: relative;
  height: 100%;
  width: 100%;
}
.table-content {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}
.chat-profile-img {
  padding: 30px;
  position: relative;
  text-align: center;
}
.edit-profile-img {
  height: 120px;
  margin: 0 auto;
  position: relative;
  width: 120px;
  cursor: pointer;
}
.edit-profile-img img {
  border-radius: 50%;
  height: auto;
  margin: 0;
  width: 120px;
}
.change-img {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: #fff;
  display: none;
  height: 100%;
  left: 0;
  line-height: 120px;
  position: absolute;
  top: 0;
  width: 100%;
}
.edit-profile-img:hover .change-img {
  display: block;
}
.edit-profile-img img {
  height: auto;
  margin: 0;
  width: 120px;
  border-radius: 50%;
}
.user-name {
  color: #333;
}
.edit-btn {
  border-radius: 40px;
  height: 36px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 36px;
}
.chat-profile-info {
  padding: 15px;
}
.user-det-list {
  list-style: none;
  padding: 0;
}
.user-det-list > li {
  padding: 6px 15px;
}
.user-det-list .text-muted {
  color: #888;
}
.transfer-files .tab-content {
  padding-top: 0;
}
.files-list {
  list-style: none;
  padding-left: 0;
}
.files-list > li {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  margin: 2px 0 0;
  padding: 10px;
}
.files-cont {
  position: relative;
}
.file-type {
  height: 48px;
  position: absolute;
  width: 48px;
}
.files-icon {
  background-color: #f3f7f9;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}
.files-icon i {
  color: #76838f;
  font-size: 20px;
}
.files-info {
  padding: 0 30px 0 50px;
}

.file-name a {
  color: #333;
}
.files-action {
  display: none;
  height: 30px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 10px;
  width: 30px;
}
.files-list > li:hover .files-action {
  display: block;
}
.file-date {
  color: #888;
  font-size: 12px;
}
.file-author a {
  color: #ff9b44;
  font-size: 12px;
  text-decoration: underline;
}
.files-action .dropdown-menu {
  left: auto;
  right: 0;
}
.files-action > li > a {
  color: #777;
}
.chat-img-attach {
  border-radius: 4px;
  margin-bottom: 12px;
  margin-left: 12px;
  position: relative;
  float: right;
}
.chat-img-attach img {
  border-radius: 4px;
  display: inline-block;
  height: auto;
  max-width: 100%;
}
.chat-placeholder {
  background: rgba(69, 81, 97, 0.6);
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 13px;
  position: absolute;
  top: 0;
  width: 100%;
}
.chat-img-name {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.chat-file-desc {
  font-size: 11px;
}
.chat-right .chat-content.img-content {
  background-color: transparent;
  border: 0;
  color: #76838f;
  padding: 0;
  text-align: right;
}
.chat-right .img-content .chat-time {
  color: #a3a3a3;
}
.chat-left .chat-img-attach {
  float: left;
}
.chat-left .chat-img-attach {
  float: left;
  margin-left: 0;
  margin-right: 12px;
}
.input-group .form-control-lg.form-control {
  border-radius: 4px 0 0 4px;
  height: 46px;
}
.chat-user-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.chat-user-list .media {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
  background-color: #fff;
}
.chat-user-list .media:hover {
  background-color: #f7f7f7;
}
.designation {
  color: #9e9e9e;
  font-size: 12px;
}
.online-date {
  color: #9e9e9e;
  font-size: 12px;
}
.drop-zone {
  width: 100%;
  height: 205px;
  border: 2px dashed #adb7be;
  text-align: center;
  padding: 25px 0 0;
  margin-bottom: 20px;
}
.drop-zone .drop-zone-caption {
  font-weight: 600;
}
.upload-list {
  padding: 0;
  list-style: none;
  margin: 0;
}
.upload-list .file-list {
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  padding: 10px 0;
}
.upload-list .file-list:first-child {
  border-top: none;
}
.upload-list .upload-wrap {
  position: relative;
  padding: 0 20px 0 0;
  margin: 0 0 5px;
}
.upload-list .file-name,
.upload-list .file-size {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.upload-list .file-name {
  padding-right: 15px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.upload-list .file-size {
  color: #888;
}
.upload-list .file-close {
  border: none;
  background: none;
  color: #ccc;
  position: absolute;
  right: 0;
  top: 2px;
}
.upload-list .file-close:hover {
  color: #f62d51;
}
.upload-list .upload-process {
  font-size: 10px;
  color: #888;
}
.upload-list .progress {
  margin-bottom: 5px;
}
.upload-list .file-name i {
  color: #888;
  margin: 0 5px 0 0;
  vertical-align: middle;
}
.upload-drop-zone {
  background-color: #fff;
  border: 2px dashed #e3e3e3;
  color: #ccc;
  height: 200px;
  line-height: 200px;
  margin-bottom: 20px;
  text-align: center;
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}
.upload-text {
  font-size: 24px;
  margin-left: 10px;
}
.files-share-list {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
}

/*-----------------
	20. Focus Label
-----------------------*/

.form-focus {
  height: 50px;
  position: relative;
}
.form-focus .focus-label {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #888;
  margin-bottom: 0;
}
.form-focus.focused .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -14px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control:focus ~ .focus-label,
.form-focus .form-control:-webkit-autofill ~ .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -14px;
  font-size: 12px;
  z-index: 1;
}
.form-focus .form-control {
  height: 39px;
  padding: 21px 12px 6px;
}
.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}
.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1000;
}
.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}
.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}
.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}
.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

/*-----------------
	21. Leave
-----------------------*/

.filter-row .btn {
  height: 38px;
  padding-top: 8px !important;
  text-transform: uppercase;
}
.action-label .label {
  display: inline-block;
  min-width: 85px;
  padding: 0.5em 0.6em;
}
.action-label i {
  margin-right: 3px;
}
.action-label .dropdown-menu .dropdown-item {
  padding: 5px 10px;
}

/*-----------------
	22. Employee
-----------------------*/

.action-icon {
  color: #777;
  font-size: 18px;
  display: inline-block;
}
.table .dropdown-menu {
  font-size: 13px;
  min-width: 120px;
  padding: 0;
}
.table .dropdown-menu .dropdown-item {
  padding: 5px 10px;
}
.dropdown-action .dropdown-toggle::after {
  display: none;
}
.profile-widget {
  /* background-color: #fff; */
  border: 1px solid #ededed;
  /* background: transparent; */
  background-color: rgba(255, 255, 255, 0.86);
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.profile-widget .user-name > a {
  color: #333;
}
.dropdown.profile-action {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
}
.profile-action .dropdown-toggle::after {
  display: none;
}
.profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}
.profile-img .avatar {
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px;
}
.profile-img .avatar .image {
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
}

.mobile-no > a {
  color: #777;
  display: inline-block;
}
.staff-mail > a {
  color: #777;
  display: inline-block;
  margin-top: 5px;
}
.staff-action-btns {
  margin-top: 10px;
}
.staff-id {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}
.view-icons {
  float: right;
  margin-right: 10px;
}
.view-icons .btn {
  /* background-color: #fff; */
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  margin-right: 5px;
  min-width: 40px;
  padding: 4px;
}
.view-icons .btn.active {
  color: #000000;
  background-color: #ffffff;
}

/*-----------------
	23. Events
-----------------------*/

.calendar {
  float: left;
  margin-bottom: 0;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: sans-serif, "Open Sans";
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
  font-family: sans-serif, "Open Sans";
}
.fc-day {
  background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}
.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}
.fc-state-hover {
  background: #f3f3f3;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #ff9b44 !important;
  color: #fff !important;
  text-shadow: none !important;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #fff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px 5px;
  text-align: center;
}
.fc .fc-button-group > .fc-button:focus,
.fc .fc-button-group > .fc-button:active,
.fc .fc-button-group > .fc-button.fc-button-active {
  background-color: #ff9b44 !important;
  color: white !important;
  text-transform: capitalize;
}
.fc .fc-button-primary:disabled {
  color: #fff !important;
  background-color: #ff9b44 !important;
  text-transform: capitalize !important;
}
.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.external-event.bg-primary {
  background-color: rgba(127, 193, 252, 0.3) !important;
  color: #7fc1fc;
}
.external-event.bg-success {
  background-color: rgba(75, 211, 150, 0.3) !important;
  color: #4bd396;
}
.external-event.bg-info {
  background-color: rgba(58, 201, 214, 0.3) !important;
  color: #3ac9d6;
}
.external-event.bg-warning {
  background-color: rgba(249, 200, 81, 0.3) !important;
  color: #f9c851;
}
.external-event.bg-danger {
  background-color: rgba(245, 112, 122, 0.3) !important;
  color: #f5707a;
}
.external-event.bg-pink {
  background-color: rgba(240, 98, 146, 0.3) !important;
  color: #f06292;
}
.external-event.bg-purple {
  background-color: rgba(107, 95, 181, 0.3) !important;
  color: #6b5fb5;
}
.external-event.bg-inverse {
  background-color: rgba(59, 62, 71, 0.3) !important;
  color: #3b3e47;
}
.external-event.bg-orange {
  background-color: rgba(255, 152, 0, 0.3) !important;
  color: #ff9800;
}
.external-event.bg-brown {
  background-color: rgba(141, 110, 99, 0.3) !important;
  color: #8d6e63;
}
.external-event.bg-teal {
  background-color: rgba(38, 166, 154, 0.3) !important;
  color: #26a69a;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: sans-serif, "Open Sans";
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: sans-serif, "Open Sans";
}
.event-form .input-group .form-control {
  height: 40px;
}

/*-----------------
	24. Profile
-----------------------*/

.profile-details {
  text-align: center;
}
.personal-info li .title {
  color: #4f4f4f;
  float: left;
  font-weight: 500;
  margin-right: 30px;
  width: 25%;
}
.personal-info .document-list {
  margin-top: -10px;
}

/* .personal-info li .info {
  color: #4f4f4f;
  float: right;
  margin-left: 30px;
  width: 50%;
} */

.personal-info li .text {
  color: #8e8e8e;
  display: block;
  overflow: hidden;
}
.personal-info li {
  margin-bottom: 10px;
}
.personal-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.profile-view {
  position: relative;
}
.profile-view .profile-img-wrap {
  height: 120px;
  width: 120px;
}
.profile-view .profile-img {
  width: 120px;
  height: 120px;
}
.profile-view .profile-img .avatar {
  font-size: 24px;
  height: 120px;
  line-height: 120px;
  margin: 0;
  width: 120px;
}
.profile-view .profile-basic {
  margin-left: 140px;
  padding-right: 50px;
}
.profile-view .pro-edit {
  position: absolute;
  right: 0;
  top: 0;
}
.edit-icon {
  background-color: #eee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #3298db;
  float: right;
  font-size: 12px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
}
.edit-icon:hover {
  background-color: #3298db;
  border-color: #3298db;
  color: #fff;
}
.delete-icon {
  color: #e30b0b;
  float: right;
  font-size: 18px;
}
.delete-icon:hover {
  color: #e30b0b;
}
.staff-msg {
  margin-top: 30px;
}
.experience-box {
  position: relative;
}
.experience-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.experience-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}
.experience-list > li {
  position: relative;
}
.experience-list > li:last-child .experience-content {
  margin-bottom: 0;
}
.experience-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}
.experience-list > li .experience-user {
  /* background: #fff; */
  height: 10px;
  left: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  width: 10px;
}
.experience-list > li .experience-content {
  /* background-color: #fff; */
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}
.experience-list > li .experience-content .timeline-content {
  color: #9e9e9e;
}
.experience-list > li .experience-content .timeline-content a.name {
  color: #616161;
  font-weight: bold;
}
.experience-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 1.35;
}
.before-circle {
  background-color: #ddd;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.skills > span {
  border: 1px solid #ccc;
  border-radius: 500px;
  display: inline-block;
  margin-bottom: 10px;
  padding: 6px 12px;
  text-align: center;
}
.profile-info-left {
  border-right: 2px dashed #ccc;
}

.pantry-info-left {
  border-right: 2px solid #c4c4c4;
  text-align: center;
  padding: 20px;
  padding-bottom: 0px !important;
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  box-shadow: unset;
  display: block;
  min-height: 44px;
  padding: 6px 6px 0;
}
.bootstrap-tagsinput .badge {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 6px;
  padding: 10px 15px;
}
.add-more a {
  color: #ff9b44;
}
.add-more a:hover {
  color: #ff851a;
}
.avatar-box {
  float: left;
}
.pro-overview .personal-info li .title {
  width: 50%;
}
.profile-box {
  min-height: 250px;
}

/*-----------------
	25. Notifications
-----------------------*/

.notifications {
  padding: 0;
  margin-top: 80px !important;
}
.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}
.notifications .notification-heading {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.45;
  color: #616161;
}
.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0px 0px 10px 10px;
}
.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}
.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
  border-bottom: none;
}
.notifications .media a {
  display: block;
  padding: 12px;
  border-radius: 2px;
}
.notifications .media a:hover {
  background-color: #fafafa;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}
.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: 500;
}
.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 11px;
  text-transform: uppercase;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}
.user-menu.nav > li > a .badge {
  color: #fff;
  font-weight: 700;
  position: absolute;
  right: 3px;
  top: 6px;
}
.user-menu.nav > li > a .badge {
  background-color: #fc6075;
}
.user-menu.nav > li > a > i {
  font-size: 20px;
  line-height: 60px;
}
.noti-details {
  color: #989c9e;
  margin-bottom: 0;
}
.noti-title {
  color: #333;
}
.noti-time {
  margin: 0;
}

/*-----------------
	26. Roles & Permissions
-----------------------*/

/* .roles-menu {
  margin-top: 20px;
} */
.roles-menu > ul {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: medium;
  color: #666666;
  font-weight: 500;
}
.roles-menu > ul > li {
  display: block;
  position: relative;
}
.roles-menu > ul > li a {
  border-left: 3px solid transparent;
  color: #333;
  display: block;
  padding: 10px 15px;
  position: relative;
}
.roles-menu > ul > li.active a {
  border-color: #1b59f8;
  color: #1b59f8;
  background-color: #eee;
}
.roles-menu > ul > li a:hover {
  background-color: #eee;
  border-color: #1b59f8;
}
.role-action {
  float: right;
  display: none;
}
.roles-menu > ul > li a:hover .role-action {
  display: block;
}
.slide-nav .sidebar {
  margin-left: 0;
  background-color: #000000;
}
/*
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .slide-nav .sidebar {
    -webkit-backdrop-filter: blur(2px) saturate(180%);
    backdrop-filter: blur(12px) saturate(180%);
  }
}
*/
/*-----------------
	27. Chat Right Sidebar
-----------------------*/

.fixed-header .custom-menu {
  margin: 1px 0 0;
}
.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}
.list-box {
  list-style: none;
  padding-left: 0;
}
.list-item {
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  position: relative;
}
.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}
.files-icon {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}
.files-icon i {
  color: #777;
  font-size: 20px;
}
.list-body {
  padding: 0 0 0 50px;
}
.message-author {
  color: #333;
  float: left;
  font-weight: 500;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-time {
  color: #888;
  float: right;
  font-size: 11px;
}
.message-content {
  color: #333;
  font-size: 13px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.files-action .dropdown-menu {
  left: auto;
  right: 0;
}

/*-----------------
	28. Projects
-----------------------*/

.team-members {
  display: inline-flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}
.team-members > li:first-child a {
  margin-left: 0;
}
.team-members > li > a {
  border: 2px solid #fff;
  border-radius: 100%;
  display: block;
  height: 30px;
  overflow: hidden;
  width: 30px;
}
.team-members .all-users {
  line-height: 28px;
  opacity: 0.8;
}
.all-users {
  background-color: #ff5e3a;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  line-height: 34px;
  text-align: center;
}
.all-users:hover,
.all-users:active {
  color: #fff;
}
.team-members img {
  width: 100%;
}
.project-title {
  margin: 0 0 5px;
}
.project-title > h5 {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
}
.project-title > a {
  color: #333;
}
.avatar-dropdown .dropdown-menu {
  min-width: unset;
  width: 100px;
  padding: 0;
}
.avatar-dropdown .avatar-group {
  min-height: 88px;
  padding: 2px;
  white-space: normal;
}
.avatar-dropdown .avatar-group .avatar {
  margin: 2px;
}
.avatar-dropdown .avatar-pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.avatar-dropdown .page-link {
  padding: 0.3rem 0.55rem;
  border-radius: 0 !important;
  border: 0;
}
.avatar-dropdown .dropdown-toggle::after {
  display: none;
}

/*-----------------
	29. Invoice
-----------------------*/

.invoice-details,
.invoice-payment-details > li span {
  float: right;
  text-align: right;
}
.table .team-members {
  flex-wrap: nowrap;
}
.table .team-members > li > a {
  width: 32px;
  height: 32px;
}
.table .team-members .all-users {
  line-height: 30px;
}
.attach-files > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.attach-files li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.attach-files li img {
  width: 50px;
}
.file-remove {
  color: #f00;
  position: absolute;
  right: -6px;
  top: -7px;
}
.attach-files li:last-child {
  margin-right: 0;
}
.inv-logo {
  height: auto;
  margin-bottom: 20px;
  max-height: 100px;
  width: auto;
}

/*-----------------
	30. Task
-----------------------*/

.task-chat-contents {
  background-color: #fff;
}
.task-chat-contents .chat-left .chat-content {
  max-width: 100%;
}
.task-chat-view {
  border-left: 1px solid #eaeaea;
}
.task-left-sidebar {
  width: 58.3333%;
}
.task-right-sidebar {
  width: 41.6667%;
}
.task-chat-view .chat-left .chat-content {
  border: 0;
  padding: 0;
}
.task-chat-view .chat-left .chat-body {
  margin-left: 50px;
}
.task-chat-view .chat-date {
  background-color: #fff;
}
.task-chat-view .chats {
  padding: 15px 15px 30px;
}
.task-chat-view .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.followers-add {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: inline-block;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}
.followers-add:hover {
  border: 1px solid #8c8c8c;
  color: #8c8c8c;
}
.completed-task-msg {
  margin-bottom: 30px;
  margin-left: 50px;
}
.task-chat-contents .chat.chat-left {
  margin-bottom: 20px;
}
.file-attached {
  color: #333;
  font-size: 13px;
}
.file-attached i {
  font-size: 16px;
}
.task-attach-img > img {
  border: 1px solid #eaeaea;
  max-width: 100%;
}
.attach-img-download {
  margin-bottom: 5px;
}
.task-chat-view .chat-left .chat-time {
  color: #a3a3a3;
  display: inline-block;
  font-size: 11px;
}
.task-chat-user {
  color: #333;
  font-size: 13px;
}
.task-chat-view .chat-content > p {
  font-size: 13px;
  margin-bottom: 0;
}
.task-time {
  color: #a3a3a3;
  display: inline-block;
  font-size: 11px;
}
.task-success {
  color: #55ce63;
  font-size: 13px;
}
.task-success a {
  color: #55ce63;
}
.task-attach-img {
  margin-bottom: 20px;
}
.task-assign {
  float: left;
}
.assign-title {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}
.task-assign > a {
  float: left;
}
.followers-add > i {
  line-height: 28px;
}
.task-followers {
  display: flex;
  margin-top: 15px;
}
.followers-title {
  margin: 5px 10px 0 0;
}
.task-line {
  border-color: #eaeaea;
}
.task-information {
  color: #a3a3a3;
  font-size: 11px;
  line-height: 17px;
  min-width: 1px;
  margin-left: 50px;
}
.task-info-line {
  overflow-wrap: break-word;
}
.task-user {
  color: #888;
  font-weight: 500;
}
.task-information .task-time {
  display: inline-block;
  padding-left: 10px;
}
.task-btn-right {
  display: none !important;
}
.task:hover .task-btn-right {
  display: table-cell !important;
}
.task-action-btn.task-check {
  text-align: left !important;
  width: 40px;
}
.add-task-btn {
  cursor: pointer;
  padding: 6px 10px;
  text-transform: uppercase;
}
.action-circle {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.action-circle .material-icons {
  color: #ccc;
  font-size: 18px;
  vertical-align: -3px;
}
.action-circle.completed {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.action-circle.completed .material-icons {
  color: #fff;
}
.action-circle.large {
  height: 24px;
  width: 24px;
}
.action-circle.large .material-icons {
  font-size: 16px;
  vertical-align: -4px;
}
.task-wrapper {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.task-wrapper .mark-all-tasks {
  text-align: right;
  padding-bottom: 15px;
}
.task-wrapper .mark-all-tasks .mark-all-tasks-container {
  overflow: hidden;
  position: relative;
}
.task-wrapper .mark-all-tasks .mark-all-btn {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
  color: #35ba67;
}
.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
  color: #666;
}
.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle {
  border: 1px solid #666;
}
.task-wrapper
  .mark-all-tasks
  .mark-all-btn#mark-all-incomplete
  .action-circle
  .material-icons {
  color: #666;
}
.task-wrapper .mark-all-tasks .mark-all-btn.move-up {
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  transform: translateY(-30px);
  position: absolute;
  right: 0;
}
.task-wrapper .mark-all-tasks .mark-all-btn.move-down {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  position: absolute;
  right: 0;
}
.task-wrapper .task-list-header h3,
.task-wrapper .task-list-footer h3 {
  font-size: 24px;
  font-weight: 400;
  color: #666;
}
.task-wrapper .task-list-body {
  max-height: 500px;
  overflow: auto;
}
.task-wrapper #task-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #eaeaea;
}
.task-wrapper #task-list li {
  margin: 0;
  padding: 0;
}
.task-wrapper #task-list li .task-container {
  display: table;
  background: #fff;
  padding: 8px 15px;
  width: 100%;
  border: 1px solid #eaeaea;
  border-bottom: none;
  box-sizing: border-box;
  position: relative;
  -webkit-transition: background 200ms ease;
  -moz-transition: background 200ms ease;
  -ms-transition: background 200ms ease;
  -o-transition: background 200ms ease;
  transition: background 200ms ease;
}
.task-wrapper #task-list li .task-container .task-label {
  display: table-cell;
  font-weight: 400;
  vertical-align: middle;
  color: #333;
  word-break: break-all;
}
.task-wrapper #task-list li .task-container .task-action-btn {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.task-wrapper
  #task-list
  li
  .task-container
  .task-action-btn
  .action-circle:hover {
  border: 1px solid #8c8c8c;
  background: #fff;
}
.task-wrapper
  #task-list
  li
  .task-container
  .task-action-btn
  .action-circle:hover
  .material-icons {
  color: #8c8c8c;
}
.task-wrapper #task-list li .task-container:hover {
  background: #ebeeef;
}
.task-wrapper #task-list li.completed .task-container {
  background: #f9f9f9;
}
.task-wrapper #task-list li.completed .task-container .complete-btn {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.task-wrapper
  #task-list
  li.completed
  .task-container
  .complete-btn
  .material-icons {
  color: #fff;
}
.task-wrapper #task-list li.completed .task-container .complete-btn:hover {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.task-wrapper
  #task-list
  li.completed
  .task-container
  .complete-btn:hover
  .material-icons {
  color: #fff;
}
.task-wrapper #task-list li.completed .task-container .task-label {
  color: #ccc;
}
.task-wrapper #task-list li.new .task-container {
  -webkit-animation: taskHighlighter 2000ms linear 1;
  animation: taskHighlighter 2000ms linear 1;
}
.task-wrapper .task-list-footer {
  position: relative;
}
.task-wrapper .task-list-footer .add-task-btn-wrapper {
  text-align: center;
}
.task-wrapper .task-list-footer .add-task-btn {
  cursor: pointer;
  border: 2px solid #9b1bcc;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.task-wrapper .task-list-footer .add-task-btn.hide {
  opacity: 1;
  visibility: visible;
  display: block;
}
.task-wrapper .task-list-footer .add-task-btn:hover {
  background: #9b1bcc;
}
.task-wrapper .task-list-footer .add-task-btn:hover .material-icons {
  color: #ebeeef;
}
.task-wrapper .task-list-footer .add-task-btn .material-icons {
  color: #9b1bcc;
  font-size: 34px;
}
.task-wrapper .task-list-footer .new-task-wrapper {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  max-height: 0;
  overflow: hidden;
}
.task-wrapper .task-list-footer .new-task-wrapper::after {
  content: "";
  display: block;
  clear: both;
}
.task-wrapper .task-list-footer .new-task-wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #eaeaea;
  border-top: 0;
  height: 45px;
  resize: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.task-wrapper .task-list-footer .new-task-wrapper textarea:focus {
  border: 1px solid #bfbfbf;
  outline: none;
}
.task-wrapper .task-list-footer .new-task-wrapper textarea.error {
  border: 1px solid #d93737;
  outline: none;
}
.task-wrapper .task-list-footer .new-task-wrapper .error-message {
  color: #d93737;
  font-style: italic;
}
.task-wrapper .task-list-footer .new-task-wrapper .btn {
  padding: 6px 12px;
  float: right;
  cursor: pointer;
}
.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
  background: #fff;
  border: 1px solid #ccc;
}
.task-wrapper .task-list-footer .new-task-wrapper.visible {
  max-height: 300px;
  overflow: auto;
}
.notification-popup {
  z-index: 1051;
  position: fixed;
  top: 20px;
  right: 10px;
  width: 300px;
  display: inline-block;
  background: #1f8fef;
  border: 1px solid #1082e4;
  color: #fff;
  padding: 20px;
  opacity: 0.8;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.notification-popup.success {
  background: #35ba67;
  border: 1px solid #2fa65c;
}
.notification-popup p {
  margin-top: 0;
  margin-bottom: 0px;
}
.notification-popup .task {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
.notification-popup .notification-text {
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
}
.notification-popup.hide {
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay {
  display: none;
  position: fixed;
  z-index: 90;
  height: 100%;
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.6);
}
.sidebar-overlay.opened {
  display: block;
}
.sidebar-menu.opened {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  margin-left: 0;
}
html.menu-opened {
  overflow: hidden;
}
html.menu-opened body {
  overflow: hidden;
}
.task-chat-view.opened {
  margin-right: 0;
}
.chat-profile-view.opened {
  margin-right: 0;
}
.task-header {
  display: flex;
}
.task-header .assignee-info {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 1 180px;
  padding: 5px 10px 5px 5px;
  position: relative;
}
.task-header .assignee-info > a {
  align-items: center;
  color: #333;
  display: flex;
}
.task-header .task-head-title {
  color: #8e8e8e;
  font-size: 12px;
}
.task-assignee {
  font-size: 13px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assignee-info:hover {
  border-color: #e3e3e3;
}
.remove-icon {
  align-items: center;
  background-color: #e3e3e3;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-right: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  width: 20px;
}
.remove-icon:hover {
  background-color: #d3d3d3;
}
.task-header .assignee-info:hover .remove-icon {
  visibility: visible;
}
.remove-icon i {
  color: #8e8e8e;
  font-size: 12px;
}
.due-icon span {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: flex;
  height: 30px;
  justify-content: center;
  line-height: 30px;
  margin-right: 10px;
  width: 30px;
}
.due-icon span i {
  font-size: 18px;
}
.task-due-date {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  flex: 0 0 160px;
  margin-left: 10px;
  padding: 5px 10px 5px 5px;
  position: relative;
}
.task-due-date:hover {
  border-color: #e3e3e3;
}
.task-header .task-due-date > a {
  align-items: center;
  color: #333;
  display: flex;
}
.due-info .due-date {
  color: #f62d51;
  font-size: 13px;
}
.task-header .task-due-date:hover .remove-icon {
  visibility: visible;
}
.task-desc {
  align-items: flex-start;
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;
}
.task-textarea {
  display: flex;
  width: 100%;
}
.task-desc-icon {
  color: #ccc;
  margin-right: 10px;
  margin-top: 5px;
}
.task-textarea .form-control {
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 13px;
  resize: none;
}
.task-textarea:hover .form-control {
  border-color: #e3e3e3;
}
.task-complete-btn {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  display: flex;
  font-size: 14px;
  padding: 6px 10px;
}
.task-complete-btn:hover {
  background-color: #f7f7f7;
  color: #333;
}
.task-complete-btn i {
  font-size: 20px;
  margin-right: 3px;
}
.task-completed {
  background-color: #35ba67;
  border-color: #2fa65c;
  color: #fff;
}
.task-completed:hover {
  background-color: #35ba67;
  border-color: #2fa65c;
  color: #fff;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button !important;
}

/*-----------------
	31. Project View
-----------------------*/

.project-user .list-box {
  margin: 0;
}
.project-files i {
  color: #76838f;
  font-size: 50px;
}
.project-user .list-item {
  border: 0 none;
  padding: 10px 0;
}
.table-border {
  border: 1px solid #ddd;
}
.file-size {
  color: #888;
}
.project-task .task-wrapper {
  padding: 0;
}
.project-task .tab-content {
  min-height: 200px;
}
.uploaded-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  margin-bottom: 20px;
  padding: 4px;
}
.uploaded-box .uploaded-img-name {
  color: #333;
  padding: 9px;
  text-align: center;
}

/*-----------------
	32. Payslip
-----------------------*/

.payslip-title {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
}

/*-----------------
	33. Attendance
-----------------------*/

.first-off {
  white-space: pre-wrap;
}
.stats-info {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
  /* text-align: center; */
  /* box-shadow: 3px 3px 5px 2px lightblue; */
}
.stats-info h6 {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 5px;
}
.stats-info h4 {
  font-size: 24px;
  margin-bottom: 0;
}
.stats-info h4 span {
  color: #727272;
  font-size: 12px;
}
.punch-det {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.punch-det h6 {
  line-height: 20px;
  margin-bottom: 0;
}
.punch-det p {
  color: #727272;
  font-size: 14px;
  margin-bottom: 0;
}
.punch-info {
  margin-bottom: 20px;
}
.punch-hours {
  align-items: center;
  background-color: #f9f9f9;
  border: 5px solid #e3e3e3;
  border-radius: 50%;
  display: flex;
  font-size: 18px;
  height: 120px;
  justify-content: center;
  margin: 0 auto;
  width: 120px;
}
.punch-btn-section {
  margin-bottom: 20px;
  text-align: center;
}
.punch-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  max-width: 100%;
  padding: 8px 40px;
}
.punch-status .stats-box {
  margin-bottom: 0;
}
.stats-list {
  height: 331px;
  overflow-y: auto;
}
.att-statistics .stats-info {
  margin-bottom: 5px;
}
.att-statistics .progress {
  height: 4px;
}
.progress {
  height: 0.5rem !important;
}
.statistics .row {
  margin-left: -5px;
  margin-right: -5px;
}
.statistics .row > div {
  padding-left: 5px;
  padding-right: 5px;
}
.stats-box {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  margin-bottom: 15px;
  padding: 5px;
}
.stats-box p {
  margin: 0;
  font-size: 12px;
}
.stats-box > h6 {
  margin-bottom: 0;
}
.stats-info:last-child .progress {
  margin-bottom: 0;
}
.stats-info p {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.recent-activity .res-activity-list {
  height: 328px;
  list-style-type: none;
  margin-bottom: 0;
  overflow-y: auto;
  padding-left: 30px;
  position: relative;
}
.recent-activity .res-activity-list li {
  margin-bottom: 15px;
  position: relative;
}
.recent-activity .res-activity-list li:last-child {
  margin-bottom: 0;
}
.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -30px;
  top: 0px;
  border: 2px solid #ff9b44;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}
.recent-activity .res-activity-time {
  color: #bbb;
  font-size: 12px;
}
.recent-activity h6 {
  line-height: 14px;
  margin-bottom: 0;
}
.recent-activity p {
  font-size: 13px;
  margin-bottom: 0;
}
.half-day {
  width: 15px;
}

/*-----------------
	34. Ticket
-----------------------*/

.ticket-header {
  padding: 6px 0;
}
.ticket-priority {
  font-size: 14px;
}
.ticket-chat-view {
  width: 33.3333%;
}
.dataTables_wrapper.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

/*-----------------
	35. Client Profile
-----------------------*/

.tab-box {
  border-bottom: 0;
  margin-bottom: 10px;
  padding: 0;
}
.team-members a.followers-add {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: inline-block;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

/*-----------------
	36. Inbox
-----------------------*/

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}
.mail-list {
  list-style: none;
  padding: 0;
}
.mail-list > li > a {
  color: #333;
  display: block;
  padding: 10px;
}
.mail-list > li.active > a {
  color: #ff9b44;
  font-weight: bold;
}
.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 600;
}
.table-inbox .fa-star {
  color: #ffd200;
}
.table-inbox .starred.fa-star {
  color: #ffd200;
}
.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}
.table-inbox {
  font-size: 14px;
  margin-bottom: 0;
}
.note-editor.note-frame {
  border: 1px solid #ccc;
  box-shadow: inherit;
}
.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}
.note-editor.note-frame.fullscreen {
  top: 60px;
}
.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}
.form-control.search-message {
  border-radius: 4px;
  height: 38px;
  margin-left: 5px;
  width: 180px;
}
.table-inbox tr {
  cursor: pointer;
}
table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}
.mail-label {
  font-size: 16px !important;
  margin-right: 5px;
}

/*-----------------
	37. Mail View
-----------------------*/

.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}
.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 200px;
}
.attach-file {
  color: #777;
  font-size: 70px;
  padding: 10px;
  text-align: center;
  min-height: 153px;
}
.attach-file > i {
  line-height: 133px;
}
.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}
.attach-filename {
  color: #777;
  font-weight: bold;
}
.attach-filesize {
  color: #999;
  font-size: 12px;
}
.attach-file img {
  height: auto;
  max-width: 100%;
}
.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}
.mailview-footer .btn-white {
  min-width: 102px;
}
.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}
.sender-name {
  display: block;
}
.receiver-name {
  color: #777;
}
.right-action {
  text-align: right;
}
.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}
.mail-view-action {
  float: right;
}
.mail-sent-time {
  float: right;
}

/*-----------------
	38. Voice call
-----------------------*/

.voice-call-avatar {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}
.voice-call-avatar .call-avatar {
  margin: 15px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
  background-color: #fff;
}
.call-duration {
  display: inline-block;
  font-size: 30px;
  margin-top: 4px;
  position: absolute;
  left: 0;
}
.voice-call-avatar .call-timing-count {
  padding: 5px;
}
.voice-call-avatar .username {
  font-size: 18px;
  text-transform: uppercase;
}
.call-icons {
  text-align: center;
  position: relative;
}
.call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}
.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  display: inline-block;
  font-size: 20px;
}
.call-icons .call-items .call-item a i {
  width: 18px;
  height: 18px;
}
.user-video {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.user-video img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.user-video video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
.my-video {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}
.my-video ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}
.my-video ul li img {
  border: 3px solid #fff;
  border-radius: 6px;
}
.end-call {
  position: absolute;
  top: 7px;
  right: 0;
}
.end-call a {
  background-color: #f06060;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  line-height: 10px;
  padding: 8px 25px;
  text-transform: uppercase;
}
.call-users {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}
.call-users ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}
.call-users ul li img {
  border-radius: 6px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  border-radius: 6px;
  font-size: 30px;
  color: #fff;
  display: none;
  top: 0;
  border: 3px solid transparent;
}
.call-users ul li a:hover .call-mute {
  display: block;
}
.call-details {
  margin: 10px 0 0px;
  display: flex;
}
.call-info {
  margin-left: 10px;
  width: 100%;
}
.call-user-details,
.call-timing {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}
.call-timing {
  color: #727272;
  display: flex;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}

/*-----------------
	39. Video Call
-----------------------*/

.content-full {
  height: 100%;
  position: relative;
  width: 100%;
}
.video-window .fixed-header {
  padding: 0;
  border: 0;
}
.video-window .fixed-header .nav > li > a {
  padding: 18px 15px;
}

/*-----------------
	40. Outgoing Call
-----------------------*/

.call-box {
  display: block;
  background: #ff9b44;
  background: -moz-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -webkit-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -ms-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  position: sticky;
  top: 0;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
}
.call-box .call-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  text-align: center;
}
.call-box .call-wrapper .call-avatar {
  margin-bottom: 50px;
  cursor: pointer;
  animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
  margin-bottom: 50px;
}
.call-box .call-wrapper .call-user h4 {
  color: #fff;
}
.call-box .call-wrapper .call-user span {
  display: block;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.call-box .call-wrapper .call-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.call-box .call-wrapper .call-items .call-item {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 100%;
  color: #fff;
  line-height: 0;
  margin: 0 5px;
  padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
  opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
  margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
  margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
  padding: 20px;
  margin: 30px 20px 0;
  background: #f06060;
  border: 1px solid #f06060;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
  padding: 20px;
  margin: 30px 20px 0;
  background: #55ce63;
  border: 1px solid #55ce63;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
  margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
  margin: 0 10px;
}
.call-box .call-avatar {
  border-radius: 100%;
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  position: relative;
  width: 100%;
}
.call-box .btn {
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s ease 0s;
}
@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}
@keyframes ripple {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    -moz-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}

/*-----------------
	41. Incoming Call
-----------------------*/

.incoming-btns {
  margin-top: 20px;
}

/*-----------------
	42. Contacts
-----------------------*/

.contacts-header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}
.contacts-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
}
.contacts-header .user-info a {
  color: #76838f;
  text-transform: uppercase;
}
.contact-left {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
}
.contacts-list {
  position: relative;
  padding: 0 70px 0 20px;
}
.contact-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.contact-list > li {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
}
.contact-cont {
  position: relative;
}
.contact-type {
  height: 48px;
  position: absolute;
  width: 48px;
}
.contact-icon {
  background-color: #f3f7f9;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}
.contact-icon i {
  color: #76838f;
  font-size: 20px;
}
.contact-info {
  padding: 0 30px 0 50px;
}
.contact-action {
  height: 30px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 10px;
  width: 30px;
}
.contact-date {
  color: #888;
  font-size: 12px;
}
.contact-author a {
  color: #1d6ad2;
  font-size: 12px;
  text-decoration: underline;
}
.contact-action .dropdown-menu {
  left: auto;
  right: 0;
}
.contact-alphapets {
  background-color: #fff;
  border-left: 1px solid #ddd;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  padding-bottom: 114px;
  position: fixed;
  right: 0;
  top: 114px;
  width: 50px;
}
.contact-box {
  display: inline-table;
  height: 100%;
  padding: 30px 15px;
  position: relative;
  width: 100%;
}
.alphapets-inner {
  height: 100%;
  overflow: auto;
}
.alphapets-inner a {
  display: block;
  text-align: center;
  padding: 2px;
  color: #333;
}

/*-----------------
	43. Chat Sidebar
-----------------------*/

.chat-sidebar {
  background-color: #fff;
  border-left: 1px solid #eaeaea;
  width: 300px;
}
.chat-sidebar .chat-contents {
  background-color: #fff;
}
.chat-sidebar .chat-left .chat-content {
  border: 0;
  padding: 0;
  border-radius: 0 !important;
  background-color: transparent;
  max-width: 100%;
}
.chat-sidebar .chat-left .chat-body {
  margin-left: 50px;
  padding-right: 0;
}
.chat-sidebar .chat-date {
  background-color: #fff;
}
.chat-sidebar .chats {
  padding: 15px 15px 30px;
}
.chat-sidebar .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.chat-sidebar .chat.chat-left {
  margin-bottom: 20px;
}
.chat-sidebar .chat.chat-left:last-child {
  margin-bottom: 0;
}
.chat-sidebar .chat-left .chat-time {
  color: #888;
  display: inline-block;
  font-size: 11px;
}
.chat-sidebar .chat-content > p {
  font-size: 13px;
  margin-bottom: 0;
}
.chat-sidebar.opened {
  margin-right: 0;
}

/*-----------------
	44. Jobs
-----------------------*/

.apply-btn {
  background: #ff9b44;
  background: -moz-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -webkit-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: -ms-linear-gradient(left, #ff9b44 0%, #fc6075 100%);
  background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
  border-color: transparent;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}
.job-list {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  display: block;
  margin-bottom: 35px;
  position: relative;
  transition: all 0.3s ease 0s;
}
.job-list-det {
  align-items: flex-start;
  display: flex;
  align-items: center;
  padding: 20px;
}
.job-list .job-list-desc {
  flex: 1 1 0;
}
.job-list h4.job-department {
  color: #777;
  font-size: 14px;
  margin-bottom: 0;
}
.job-list h3.job-list-title {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
.job-list .job-list-footer {
  background-color: #f9f9f9;
  border-radius: 0 0 4px 4px;
  position: relative;
  padding: 20px;
}
.job-list .job-list-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.job-list .job-list-footer ul li {
  color: #777;
  display: inline-block;
  margin-right: 14px;
}
.job-list .job-list-footer ul li i {
  color: #777;
  margin-right: 3px;
  position: relative;
}
.job-list .job-list-footer ul li:last-child {
  margin-right: 0;
}
.page-wrapper.job-wrapper {
  margin-left: 0;
}
.job-types {
  background-color: transparent;
  border: 1px solid #ff9b44;
  color: #ff9b44;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  text-align: center;
}

/*-----------------
	45. Job Details
-----------------------*/

.job-info {
  background-color: #fff;
  border: 1px solid #efefef;
  float: left;
  margin: 0 0 30px;
  padding: 30px;
  position: relative;
  width: 100%;
}
.job-title {
  color: #585858;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}
.job-info .job-dept {
  color: #737882;
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.job-post-det {
  display: inline-block;
  margin-bottom: 5px;
  width: 100%;
  color: #737882;
  list-style: none;
  margin: 0;
  padding: 0;
}
.job-content li {
  line-height: 28px;
}
.job-post-det li {
  float: left;
  list-style: none;
  margin-bottom: 10px;
  margin-right: 20px;
}
.job-det-info {
  background-color: #fff;
  margin: 0 0 15px;
  padding: 0 0 6px;
}
.info-list {
  color: #888;
  padding-top: 20px;
}
.info-list span {
  color: #ff9b44;
  float: left;
  font-size: 20px;
  line-height: 14px;
}
.info-list p {
  line-height: 1.7;
  margin: 0 0 0 40px;
}
.info-list > h5 {
  color: #585858;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 40px;
  text-transform: uppercase;
}
.app-ends {
  color: #46cd38;
  font-size: 13px;
}
.job-btn {
  border: 2px solid #ff9b44;
  color: #ff9b44;
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.job-btn:hover {
  background-color: #ff9b44;
  color: #fff;
}
.job-widget {
  background-color: #fff;
  border: 1px solid #efefef;
  float: left;
  margin: 0 0 30px;
  padding: 30px;
  position: relative;
  width: 100%;
}
.job-desc-title h2 {
  font-size: 20px;
  margin-bottom: 0;
}
.job-widget h4 {
  color: #585858;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px;
}
.job-description > p {
  color: #727272;
  line-height: 28px;
}
.square-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.square-list li {
  color: #727272;
  line-height: 24px;
  list-style: none;
  margin: 10px 0 0 20px;
  position: relative;
}
.square-list li:first-child {
  margin-top: 0;
}
.square-list li:before {
  font-family: fontawesome;
  margin: 0;
  position: relative;
  color: #ff9b44;
  float: left;
  margin-left: -20px;
  display: block;
  content: "\f0c8";
  font-size: 8px;
}
.text-blue {
  color: #009ce7;
}

/*-----------------
	46. Notification Settings
-----------------------*/

.notification-list .list-group-item {
  padding: 10px;
}
.notification-list .status-toggle {
  float: right;
}

/*-----------------
	47. Leave Settings
-----------------------*/

.leave-item {
  max-width: 653px;
}
.leave-row {
  display: flex;
  margin-bottom: 15px;
}
.carry-forward-itemview {
  margin-bottom: 15px;
}
.earned-leave-itemview {
  margin-bottom: 15px;
}
.leave-row .leave-left {
  flex: 1 1 auto;
}
.leave-row .leave-left .input-box {
  max-width: 410px;
}
.leave-item .form-group {
  margin-bottom: 0;
}
.leave-right {
  align-items: center;
  display: flex;
  flex: 0 0 200px;
  justify-content: end;
  margin-top: 31px;
  min-height: 44px;
  text-align: right;
}
.leave-right .btn {
  min-height: 44px;
  min-width: 75px;
}
.leave-right .btn + .btn {
  margin-left: 10px;
}
.leave-edit-btn {
  color: #216ef4;
  background-color: transparent;
  border: 0;
  padding: 0 6px;
  transition: unset;
}
.leave-edit-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.leave-inline-form {
  align-items: center;
  display: flex;
  min-height: 44px;
}
.leave-header {
  align-items: center;
  color: #212536;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.leave-header .title {
  flex: 1 1 auto;
}
.leave-header .leave-action {
  flex: 1 1 auto;
  text-align: right;
}
.leave-table .l-name {
  width: 200px;
}
.leave-table .l-days {
  width: 140px;
}
.leave-box .subtitle {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: normal;
  margin: 5px auto 0 5px;
}
.leave-duallist {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
  display: block;
  padding: 15px;
}
.card-title.with-switch {
  display: flex;
  justify-content: space-between;
}
.btn.leave-delete-btn {
  font-weight: 500;
  margin-left: 10px;
  min-height: 30px;
  padding: 2px 15px;
}

/*-----------------
	48. Termination
-----------------------*/

.add-group-btn {
  display: flex;
}
.add-group-btn .btn {
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.add-group-btn .btn i {
  margin-right: 5px;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #ff9b44;
  text-shadow: unset;
}

/*-----------------
	49. Loader
-----------------------*/

#loader-wrapper {
  background-color: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
#loader {
  background-position: center center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}
.loader-ellips {
  font-size: 20px;
  position: relative;
  width: 64px;
  margin: 100px auto 0;
}
.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
  left: 0;
}
.loader-ellips__dot:nth-child(3) {
  left: 1.5em;
}
.loader-ellips__dot:nth-child(4) {
  left: 3em;
}
.loader-ellips__dot:nth-child(1) {
  animation-name: reveal;
}
.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
  animation-name: slide;
}
.loader-ellips__dot:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
}
@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slide {
  to {
    transform: translateX(1.5em);
  }
}
.loader-ellips__dot:nth-child(1) {
  background: #404040;
}
.loader-ellips__dot:nth-child(2) {
  background: #ff9b44;
}
.loader-ellips__dot:nth-child(3) {
  background: #404040;
}
.loader-ellips__dot:nth-child(4) {
  background: #404040;
}

/*-----------------
	50. Payroll Items
-----------------------*/

.page-menu .nav-tabs > li > a {
  font-size: 18px;
}
.table-radius.table {
  width: 100%;
  background: #fff;
  border-spacing: 0;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
.table-radius.table thead td,
.table-radius.table thead th {
  border-top: 0 none !important;
  padding: 20px !important;
}

.table-radius.table tbody td,
.table-radius.table tbody th {
  padding: 15px 20px !important;
}

/*-----------------
	51. Error
-----------------------*/

.error-page {
  align-items: center;
  background-color: rgba(255, 155, 68, 0.2);
  color: #1f1f1f;
  display: flex;
}
.main-wrapper {
  height: 100vh;
  overflow-x: hidden;
}
.error-page .main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.error-box {
  margin: 0 auto;
  max-width: 480px;
  padding: 40px 0;
  text-align: center;
  width: 100%;
}
.error-box h1 {
  color: #ff9b44;
  font-size: 10em;
}
.error-box p {
  margin-bottom: 30px;
}
.error-box .btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

/*-----------------
	52. Lock Screen
-----------------------*/

.lock-user {
  margin-bottom: 20px;
  text-align: center;
}
.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

/*-----------------
	53. OTP
-----------------------*/

.otp-wrap {
  margin-bottom: 30px;
  text-align: center;
}
.otp-input {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  height: 70px;
  line-height: 29px;
  margin-right: 15px;
  text-align: center;
  width: 70px;
}
.otp-input:last-child {
  margin-right: 0;
}

/*-----------------
	54. Salary Settings
-----------------------*/

.settings-widget + .settings-widget {
  margin-top: 30px;
}
.row.row-sm {
  margin-left: -5px;
  margin-right: -5px;
}
.row.row-sm > div {
  padding-left: 5px;
  padding-right: 5px;
}
.set-btn {
  min-height: 44px;
}

/*-----------------
	55. Components
-----------------------*/

.comp-buttons .btn {
  margin-bottom: 5px;
}
.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}
.progress-example .progress {
  margin-bottom: 1.5rem;
}
.progress-xs {
  height: 4px;
}
.progress-sm {
  height: 15px;
}
.progress.progress-sm {
  height: 6px;
}
.progress.progress-md {
  height: 8px;
}
.progress.progress-lg {
  height: 18px;
}
.stickyside .sidebar-menu > ul > li > a.active {
  color: #ff9b44;
}
.comp-section {
  /* margin-bottom: 30px; */
  /* padding-bottom: 30px; */
  /* padding-top: 30px; */
}
.comp-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.comp-section .section-title {
  font-size: 1.125rem;
  /* margin-bottom: 20px; */
  text-decoration: underline;
  text-transform: uppercase;
}

/*-----------------
	56. Search
-----------------------*/

.main-search {
  margin-bottom: 20px;
}
.search-result {
  margin-bottom: 30px;
}
.search-result u {
  color: #ff9b44;
}
.search-result p {
  color: #777;
  margin-bottom: 0;
}
.search-lists .nav-tabs.nav-tabs-solid {
  background-color: #fff;
  border: 1px solid #e3e3e3;
}
.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}
.top-nav-search .form-control {
  border-radius: 50px;
  height: 40px;
  padding: 10px 50px 10px 15px;
}
.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: #999;
  min-height: 40px;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
}
.top-nav-search .form-control::-webkit-input-placeholder {
  color: #999;
}
.top-nav-search .form-control::-moz-placeholder {
  color: #999;
}
.top-nav-search .form-control:-ms-input-placeholder {
  color: #999;
}
.top-nav-search .form-control::-ms-input-placeholder {
  color: #999;
}
.top-nav-search .form-control::placeholder {
  color: #999;
}
.responsive-search {
  display: none;
  color: #333;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
}
.top-nav-search.active form {
  display: block;
  left: 0;
  position: absolute;
}

/*-----------------
	57. Knowledgebase
-----------------------*/

.topics {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 20px;
}
.topics .topic-title {
  color: #333;
  margin-bottom: 20px;
  padding: 0 25px 0 0;
  position: relative;
}
.topics .topic-title a {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
}
.topics .topic-title a:hover {
  color: #ff9b44;
}
.topics .topic-title a span {
  color: #ff9b44;
}
.topics .topic-title a i {
  color: #ff9b44;
  margin-right: 5px;
}
.topics .topics-list {
  padding: 0;
  margin: 0;
}
.topics .topics-list li {
  list-style: none;
  line-height: 2;
}
.topics .topics-list li a {
  padding-left: 25px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  outline: 0 none;
}
.topics .topics-list li a:hover {
  color: #ff9b44;
}
.topics .topics-list li:before {
  font-family: FontAwesome;
  content: "\f016";
  color: #555;
  font-size: 15px;
  left: 0;
  position: absolute;
  top: 0;
}
.topics .topics-list li {
  position: relative;
}
.widget {
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding: 20px;
}
.widget:last-child {
  margin-bottom: 0;
}
.widget h4 {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
.widget > ul {
  margin-bottom: 0;
  padding: 0;
  margin: 0;
}
.widget-category li {
  line-height: 35px;
  list-style: none;
  position: relative;
}
.widget-category li:before {
  font-family: FontAwesome;
  content: "\f016";
  color: #555;
  font-size: 18px;
  left: 0;
  position: absolute;
  top: 0;
}
.widget-category li a {
  color: #5e6977;
  padding-left: 25px;
}
.widget-category li a:hover {
  color: #ff9b44;
}
.post {
  margin-bottom: 50px;
}
.post .meta {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  font-size: 12px;
  margin: 0 0 20px;
  padding: 10px;
}
.post .meta p {
  padding: 15px 0;
}
.post .meta li {
  display: inline-block;
  color: #86939e;
}
.post .meta li span {
  color: #43484d;
}
.post .meta li:after {
  content: "/";
  padding: 0 10px;
}
.post .meta li:last-child:after {
  content: none;
}
.post img {
  padding: 40px 0;
  max-width: 100%;
}
.feedback {
  background-color: #f9f9f9;
  border: 1px solid #efefef;
  padding: 20px;
  margin-bottom: 50px;
}
.feedback h3 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}
.feedback p:last-child {
  margin-bottom: 0;
}
.post .meta a {
  color: #ff9b44;
}
.widget .widget-title i {
  color: #ff9b44;
  font-size: 24px;
  margin-right: 10px;
}
.post h1.post-title {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}
.post h2 {
  font-size: 24px;
  font-weight: 400;
}
.post > p {
  margin-bottom: 20px;
}
.comment-section {
  float: left;
  width: 100%;
}
.comment-section h3 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 30px;
}
ul.comment-list {
  border-top: 0;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
ul.comment-list li {
  border: 0;
  box-sizing: border-box;
  clear: both;
  float: left;
  list-style: none;
  margin: 0 0 30px;
  padding: 0 0 0 70px;
  position: relative;
  width: 100%;
}
ul.comment-list li:last-child {
  margin-bottom: 0;
}
ul.comment-list li div.comment {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
ul.comment-list li .comment-author {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
ul.comment-list li .comment-author img {
  border-radius: 50px;
  float: left;
  height: 50px;
  margin: 0;
  max-height: 50px;
  max-width: 50px;
  width: 50px;
}
ul.comment-list li .comment-details {
  margin: 10px 0;
  padding: 0 0 10px;
  position: relative;
}
ul.comment-list li .author-name > a {
  color: #2f363f;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
}
ul.comment-list li .author-name span {
  float: left;
  font-size: 14px;
  width: 100%;
}
ul.comment-list li .comment-body {
  float: left;
  margin: 10px 0 0;
  width: 100%;
}
ul.comment-list li .comment-body p {
  line-height: 28px;
}
ul.comment-list li .reply a {
  clear: both;
  color: #2f363f;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 0;
}
ul.comment-list li ul.children {
  border: 0 none;
  clear: both;
  float: left;
  margin: 10px 0;
  padding: 0;
  width: 100%;
}
.comment-section .comment-reply {
  float: left;
  margin-top: 30px;
  width: 100%;
}
.comment-reply form {
  float: left;
  margin-bottom: 20px;
  width: 100%;
}
.comment-reply .form-control {
  background-color: #f9f9f9;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  min-height: 50px;
}
.comment-reply .form-control:focus {
  box-shadow: unset;
}

/*-----------------
	58. FAQ
-----------------------*/

.faq-card .card-header {
  background-color: #fff;
  padding: 20px;
  position: relative;
}
.faq-card .card {
  border-color: #ddd;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: hidden;
}
.faq-card .card .card-header h4 {
  margin-bottom: 0;
}
.faq-card .card .card-header h4 > a {
  color: #090909;
  display: block;
  font-size: 20px;
  font-weight: 400;
  padding-right: 60px;
  position: relative;
}
.faq-card .card .card-header h4 > a:after {
  font-family: FontAwesome;
  font-size: 19px;
  position: absolute;
  right: 0px;
  font-weight: 400;
}
.faq-card .card .card-header h4 > a.collapsed:after {
  content: "\f067";
}
.faq-card .card .card-header h4 > a:not(.collapsed):after {
  content: "\f068";
}
.faq-card .card .card-header h4 > label {
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding-right: 60px;
  position: relative;
}
.faq-card .card .card-header h4 > label:after {
  font-family: FontAwesome;
  font-size: 19px;
  position: absolute;
  right: 0px;
  font-weight: 400;
  top: 0;
}
.faq-card .card .card-header h4 > label.collapsed:after {
  content: "\f067";
}
.faq-card .card .card-header h4 > label:not(.collapsed):after {
  content: "\f068";
}
.small-container {
  margin: auto;
  max-width: 1100px;
  padding: 30px;
}
.inner-header {
  margin-bottom: 30px;
}

/*-----------------
	59. Employee Dashboard
-----------------------*/

.welcome-box {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  display: flex;
  margin: -30px -30px 30px;
  padding: 20px;
  position: relative;
}
.welcome-img {
  margin-right: 15px;
}
.welcome-img img {
  border-radius: 8px;
  width: 60px;
}
.welcome-det p {
  color: #777;
  font-size: 18px;
  margin-bottom: 0;
}
.dash-section {
  margin-bottom: 30px;
}
.dash-list {
  background-color: white;
  border-color: #f1f6f8;
  color: #2e424d;
  display: flex;
  margin: 10px 0 0;
}
.dash-card-container {
  display: flex;
  flex-direction: row;
}
.dash-card-icon {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 26px;
  padding-right: 15px;
}
.dash-card-icon img {
  width: 30px;
}
.dash-card-content {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.e-avatar {
  background-color: #fff;
  border: 3px solid #e7e7e7;
  border-radius: 50%;
  height: 45px;
  position: relative;
  width: 45px;
  margin-left: -20px;
}

.e-avatar:first-of-type {
  margin-left: 0 !important;
}
.dash-card-avatars {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0 15px;
}
.e-avatar > img {
  border-radius: 50%;
  width: 100%;
}
.dash-sec-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.dash-info-list .dash-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  color: #575757;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 15px;
  width: 100%;
}
.dash-card-content p {
  font-size: 22px;
  margin-bottom: 0;
}
.dash-sec-content .dash-info-list + .dash-info-list {
  margin-top: 15px;
}
.dash-sidebar h5 {
  color: inherit;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.dash-sidebar p {
  color: #777;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0;
  text-transform: uppercase;
}
.dash-sidebar .card {
  border-radius: 8px;
  box-shadow: unset;
}
.time-list {
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;
}
.request-btn {
  text-align: center;
}
.dash-stats-list {
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
}
.dash-stats-list + .dash-stats-list {
  border-left: 1px solid #e7e7e7;
}
.dash-stats-list h4 {
  color: #1f1f1f;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;
}

/*-----------------
	60. Performance Review
-----------------------*/

.review-table tr {
  background-color: #fff;
}
.review-section {
  margin-bottom: 30px;
}
.review-header {
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-bottom: 0;
  padding: 15px;
  text-align: center;
}
.review-header h3 {
  font-size: 1.4rem;
  margin-bottom: 3px;
}
.review-header p {
  margin-bottom: 0;
}
.review-table .form-control {
  height: 36px;
}
.grade-span .badge {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  min-width: 75px;
  padding: 4px 12px;
  text-align: center;
}
.review-table .select2-container .select2-selection--single {
  height: 36px;
}
.review-table
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 34px;
}
.review-table
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 34px;
}

/*-----------------
	61. Kanban Board
-----------------------*/

.card > .task-header {
  line-height: 15px;
  display: flex;
  justify-content: space-between;
}
.task-header {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px 5px;
}
.task-header a {
  color: #1f1f1f;
}
.task-body {
  padding: 0 15px 15px;
  font-size: 13px;
}
.kanban-list:last-child {
  margin-right: 0;
}
.kanban-list > .kanban-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.kanban-list > .kanban-header > .status-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.kanban-list > .kanban-wrap {
  padding: 20px;
}
.kanban-list > .kanban-wrap > .drag-placeholder {
  height: 146px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
}
.kanban-list > .kanban-wrap.ks-empty > .card.drop-here {
  display: block;
}
.kanban-list > .kanban-wrap > .card {
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: unset;
  margin: 0 0 12px;
}
.kanban-list > .kanban-wrap > .card.drop-here {
  display: none;
  text-align: center;
  background: transparent;
  padding: 15px;
  border-style: dashed;
}
.kanban-list > .kanban-wrap > .card.drop-here > a {
  color: #333;
  font-weight: 500;
}
.kanban-list > .kanban-wrap > .card:last-child {
  margin-bottom: 0;
}
.kanban-list > .kanban-wrap > .card.ui-sortable-helper {
  box-shadow: 0 2px 20px 0 #d7dceb;
}
.kanban-list > .kanban-wrap > .card > .task-header {
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 15px 15px 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kanban-list > .kanban-wrap > .card > .task-body {
  padding: 0 15px 15px;
}

.kanban-list > .kanban-wrap > .card > .kanban-box > .task-body > .kanban-info {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress {
  margin-bottom: 0;
  margin-right: 10px;
  width: 100%;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #42a5f5;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer
  .ks-icon {
  color: #d7dceb;
  font-size: 20px;
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 1px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer
  > .task-users
  > .task-avatar
  > img {
  width: 24px;
  height: 24px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-footer
  > .task-users
  > .ks-amount {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: 1px;
}
.kanban-list.kanban-danger {
  background-color: transparent;
}
.kanban-list.kanban-danger > .kanban-header {
  background-color: #ef5350;
}
.kanban-list.kanban-danger > .kanban-wrap > .card.drop-here {
  border-color: #f7c1b7;
}
.kanban-list.kanban-danger
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ef5350;
}
.kanban-list.kanban-success {
  /* background-color: #edf7ee; */
}
.kanban-list.kanban-success > .kanban-header {
  background-color: #4caf50;
}
.kanban-list.kanban-success > .kanban-wrap > .card.drop-here {
  border-color: #abebc6;
}
.kanban-list.kanban-success
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #4caf50;
}
.kanban-list.kanban-info {
  /* background-color: #e7f3fe; */
}
.kanban-list.kanban-info > .kanban-header {
  background-color: #42a5f5;
}
.kanban-list.kanban-info > .kanban-wrap > .card.drop-here {
  border-color: #a6dbf9;
}
.kanban-list.kanban-warning {
  /* background-color: #fdfcf3; */
}
.kanban-list.kanban-warning > .kanban-header {
  background-color: #ffb300;
}
.kanban-list.kanban-warning > .kanban-wrap > .card.drop-here {
  border: 1px solid #f2e49e;
}
.kanban-list.kanban-warning
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ffb300;
}
.task-avatar {
  border-radius: 50%;
  position: relative;
}
.kanban-box.ui-sortable-handle {
  cursor: move;
}
.card > .task-board-header {
  line-height: 15px;
  display: flex;
  justify-content: space-between;
}
.task-board-header {
  font-size: 14px;
  font-weight: 500;
  padding: 15px 15px 5px;
  display: flex;
  justify-content: space-between;
}
.task-board-header a {
  color: #1f1f1f;
}
.task-board-body {
  padding: 0 15px 15px;
  font-size: 13px;
}
.progress > .progress-bar {
  background-color: #25628f;
}
.kanban-cont {
  display: flex;
  overflow: auto;
  padding-bottom: 20px;
}
.kanban-list {
  align-self: flex-start;
  margin-right: 30px;
  min-width: 300px;
  width: 300px;
}
.kanban-list:last-child {
  margin-right: 0;
}
.kanban-list > .kanban-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.kanban-list > .kanban-header > .status-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.kanban-list > .kanban-wrap {
  padding: 20px;
}
.kanban-list > .kanban-wrap > .drag-placeholder {
  height: 146px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
}
.kanban-list > .kanban-wrap > .card {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: unset;
  margin: 0 0 12px;
}
.kanban-list > .kanban-wrap > .card.active {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.kanban-list > .kanban-wrap > .card:last-child {
  margin-bottom: 0;
}
.kanban-list > .kanban-wrap > .card.ui-sortable-helper {
  box-shadow: 0 2px 20px 0 #d7dceb;
}
.kanban-list > .kanban-wrap > .card > .task-board-header {
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 15px 15px 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kanban-list > .kanban-wrap > .card > .task-board-body {
  padding: 0 15px 15px;
}

.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress {
  margin-bottom: 0;
  margin-right: 10px;
  width: 100%;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #42a5f5;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
  align-items: end;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-footer
  > .task-users
  > .task-avatar
  > img {
  width: 24px;
  height: 24px;
}
.kanban-list
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-footer
  > .task-users
  > .task-user-count {
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: 1px;
}
.kanban-list.kanban-danger > .kanban-wrap > .card.active {
  border-color: #ef5350;
}
.kanban-list.kanban-success > .kanban-wrap > .card.active {
  border-color: #4caf50;
}
.kanban-list.kanban-info > .kanban-wrap > .card.active {
  border-color: #42a5f5;
}
.kanban-list.kanban-warning > .kanban-wrap > .card.active {
  border-color: #ffb300;
}
.kanban-list.kanban-danger {
  /* background-color: #fef7f6; */
}
.kanban-list.kanban-danger > .kanban-header {
  background-color: #ef5350;
}
.kanban-list.kanban-danger
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ef5350;
}
.kanban-list.kanban-success {
  /* background-color: #edf7ee; */
}
.kanban-list.kanban-success > .kanban-header {
  background-color: #4caf50;
}
.kanban-list.kanban-success
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #4caf50;
}
.kanban-list.kanban-info {
  /* background-color: #e7f3fe; */
}
.kanban-list.kanban-info > .kanban-header {
  background-color: #42a5f5;
}
.kanban-list.kanban-warning {
  /* background-color: #fdfcf3; */
}
.kanban-list.kanban-warning > .kanban-header {
  background-color: #ffb300;
}
.kanban-list.kanban-warning
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ffb300;
}
.kanban-list.kanban-purple {
  background-color: #f1effd;
}
.kanban-list.kanban-purple > .kanban-header {
  background-color: #7460ee;
}
.kanban-list.kanban-purple
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #7460ee;
}
.kanban-list.kanban-primary {
  background-color: #fff5ec;
}
.kanban-list.kanban-primary > .kanban-header {
  background-color: #ff9b44;
}
.kanban-list.kanban-primary
  > .kanban-wrap
  > .card
  > .kanban-box
  > .task-board-body
  > .kanban-info
  > .progress
  .progress-bar {
  background-color: #ff9b44;
}
.task-avatar {
  border-radius: 50%;
  position: relative;
}
.kanban-box.ui-sortable-handle {
  cursor: move;
}
.kanban-action > a {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  display: inline-flex;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  justify-content: center;
}
.task-board-color .board-color-list {
  display: flex;
  height: 45px;
}
.task-board-color .board-color-list .board-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  padding-left: 0;
  cursor: pointer;
  width: 36px;
  height: 36px;
}
.task-board-color .board-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.board-control > .board-control-input:checked ~ .board-indicator:before {
  content: "\f00c";
  display: block;
  width: 16px;
  height: 16px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  border: none;
  position: relative;
  font-family: FontAwesome;
  text-align: center;
  line-height: 16px;
}
.board-control > .board-control-input:checked ~ .board-indicator::before {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.board-control > .board-indicator {
  width: 36px;
  height: 36px;
}
.board-control.board-primary > .board-indicator {
  background: #ff9b44;
}
.board-control.board-success > .board-indicator {
  background: #4caf50;
}
.board-control.board-info > .board-indicator {
  background: #42a5f5;
}
.board-control.board-purple > .board-indicator {
  background: #7460ee;
}
.board-control.board-warning > .board-indicator {
  background: #ffb300;
}
.board-control.board-danger > .board-indicator {
  background: #ef5350;
}
.kanban-board.task-wrapper .kanban-list {
  width: 100%;
}
.board-view-header {
  margin-bottom: 30px;
}
.pro-teams {
  display: inline-flex;
  margin-right: 15px;
  text-align: center;
  margin-bottom: 20px;
}
.pro-teams .pro-team-members {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.pro-teams h4 {
  margin-bottom: 0;
  margin-right: 6px;
  font-size: 16px;
}
.pro-team-lead {
  display: flex;
  align-items: center;
}
.board-view-header .view-icons {
  float: right;
  margin-right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 2px 7px;
  border-radius: 4px;
}
.board-view-header .view-icons .btn {
  margin: 0;
  padding: 0;
}
.board-view-header .view-icons a.btn + a.btn {
  margin-left: 5px;
}
.pro-progress-bar {
  display: flex;
  align-items: center;
}
.pro-progress-bar h4 {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 13px;
  text-transform: uppercase;
}
.pro-progress-bar .progress {
  width: 100%;
  margin-bottom: 0;
  margin-right: 10px;
  height: 15px;
  background-color: #e3e3e3;
  box-shadow: unset;
}
.pro-progress-bar .progress > .progress-bar {
  background-color: #ff9b44;
}
.pro-teams .avatar-group {
  display: inline-flex;
}
.pro-teams .avatar-group .avatar {
  margin-right: 0;
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  background-color: transparent;
}
.pro-teams .avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.pro-teams .avatar-group .avatar .border {
  border: 3px solid #fff !important;
}
.pro-teams .avatar-group .avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.border-white {
  border-color: #fff !important;
}
.pro-teams .avatar-group .avatar:hover {
  z-index: 1;
}
.pro-teams .avatar .avatar-title {
  font-size: 14px;
  border-radius: 50%;
}
.pro-teams .avatar-title {
  width: 100%;
  height: 100%;
  background-color: #ff9b44;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-date {
  display: block;
}
.task-priority {
  padding: 0.3em 0.6em 0.3em;
}
.task-follower-list {
  display: flex;
  margin-top: 15px;
}
.task-follower-list span {
  position: relative;
}
.task-follower-list span i {
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: 5px;
  color: #f00;
}
.add-new-task {
  text-align: center;
  padding: 0 20px 20px;
}
.add-new-task a {
  color: #333;
  font-size: 14px;
}
.project-add-form {
  display: none;
  margin-top: 10px;
}
.project-add-form.opened {
  display: block;
}
#myOverviewDiv {
  position: absolute;
  width: 200px;
  height: 100px;
  top: 10px;
  left: 10px;
  background-color: #f2f2f2;
  z-index: 300; /* make sure its in front */
  border: solid 1px #7986cb;
}
/*-----------------
	62. File Manager
-----------------------*/
.wday-box input {
  opacity: 0;
}
.wday-box .checkmark {
  border: 2px solid #ff9b44;
  width: 40px;
  height: 40px;
  line-height: 35px;
  padding-left: 13px !important;
  color: #ff9b44;
  display: block;
}
.wday-box input:checked ~ .checkmark {
  background: #ff9b44;
  color: #fff;
}
.table-responsive {
  white-space: nowrap;
}
.user-add-shedule-list a {
  display: inline-block;
  padding: 10px;
  border: 1px dashed #a7a7a7;
  color: #a7a7a7;
  border-radius: 5px;
}
.user-add-shedule-list h2 a {
  border: 2px dashed #1eb53a;
  padding: 10px;
  color: #1eb53a;
}
.user-add-shedule-list span {
  justify-content: center;
  display: flex;
}
.file-wrap {
  border: 1px solid #e0e3e4;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.file-wrap .file-sidebar {
  border-right: 1px solid #e0e3e4;
  left: 0;
  position: absolute;
  z-index: 5;
  width: 300px;
  transition: 0.3s ease-in-out;
}
.file-wrap .file-sidebar .file-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e3e4;
  color: #324148;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}
.file-wrap .file-sidebar .file-header span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.file-wrap .file-sidebar .file-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}
.file-wrap .file-sidebar .file-search .input-group {
  width: 100%;
}
.file-wrap .file-sidebar .file-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}
.file-wrap .file-sidebar .file-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.file-wrap .file-sidebar .file-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}
.file-wrap .file-scroll {
  max-height: calc(100vh - 259px);
  min-height: 497px;
  overflow-y: auto;
}
.file-wrap .file-sidebar .file-pro-list {
  background-color: #fff;
  padding: 15px;
}
.file-side-close {
  display: none;
  background-color: #eee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #bbb;
  font-size: 14px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
  position: absolute;
  right: 15px;
}
.file-wrap .file-content {
  flex: 0 0 100%;
  max-width: 100%;
}
.file-wrap .file-content .file-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e3e4;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}
.file-wrap .file-content .file-body {
  background-color: #f5f5f6;
}
.dropdown-file {
  position: absolute;
  top: 10px;
  right: 10px;
}
.dropdown-file .dropdown-link {
  color: #777;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dropdown-file .dropdown-link:hover,
.dropdown-file .dropdown-link:focus {
  color: #1b2e4b;
}
.dropdown-file .dropdown-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.card-file {
  padding: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.card-file:hover,
.card-file:focus {
  border-color: #ccc;
}
.card-body {
  background-color: transparent;
}
.card-file .card-body {
  padding: 15px 0 0;
}
.card-file .card-footer {
  font-size: 11px;
  color: #a9a9a9;
  padding: 10px 0 0;
  border-top-width: 0;
  background-color: transparent;
}
.card-file h6 {
  margin-bottom: 0;
  font-size: 0.875rem;
}
.card-file h6 a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  color: #333;
}
.card-file span {
  font-size: 12px;
  color: #888;
}
.card-file-thumb {
  align-items: center;
  background-color: #f5f5f5;
  color: #777;
  display: flex;
  font-size: 48px;
  height: 120px;
  justify-content: center;
}
.file-wrap .file-content .file-body .file-content-inner {
  padding: 15px;
  width: 100%;
}
.file-wrap.file-sidebar-toggle .file-sidebar {
  left: -331px;
}
.file-wrap.file-sidebar-toggle .file-cont-wrap {
  margin-left: 0;
}
.file-wrap .file-cont-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: 300px;
  transition: 0.4s ease-in-out;
}
.file-wrap .file-cont-wrap .file-cont-inner {
  flex: 0 0 100%;
  max-width: 100%;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e0e3e4;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header > span {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-sidebar-toggle {
  color: #333;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options {
  display: flex;
}
.file-wrap .file-cont-wrap .file-cont-inner .file-cont-header .file-options > a,
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-options
  > span {
  color: #333;
  display: inline-block;
  font-size: 20px;
  margin-left: 25px;
}
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-options
  > a:first-child,
.file-wrap
  .file-cont-wrap
  .file-cont-inner
  .file-cont-header
  .file-options
  > span:first-child {
  margin-left: 0;
}
.file-wrap .file-content .file-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}
.file-wrap .file-content .file-search .input-group {
  width: 100%;
}
.file-wrap .file-content .file-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}
.file-wrap .file-content .file-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}
.file-wrap .file-content .file-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}
.btn-file {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.btn-file input {
  cursor: pointer;
  direction: ltr;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.file-menu {
  display: inline-block;
  margin: 0 0 10px;
  padding: 0;
  width: 100%;
}
.file-menu li {
  display: inline-block;
  width: 100%;
}
.file-menu li + li {
  margin-top: 2px;
}
.file-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}
.file-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}
.file-menu li a:hover,
.file-menu li.active a,
.file-menu li a:focus {
  background: rgba(33, 33, 33, 0.05);
}
.show-more {
  text-align: center;
}
.show-more a {
  position: relative;
  padding: 0 20px 0 15px;
}
.show-more a:before {
  content: "\f107";
  top: 50%;
  right: 0;
  position: absolute;
  font-size: 18px;
  line-height: inherit;
  font-family: "FontAwesome";
  transform: translateY(-50%);
}

/*-----------------
	63. Subscriptions
-----------------------*/

.pricing-box {
  text-align: center;
}
.pricing-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.pricing-box ul li {
  line-height: 35px;
}
.pricing-box ul li i {
  color: #00bf6f;
  margin-right: 5px;
}
.equal-height-cards > div > div.card {
  height: 100%;
}
.mb-30 {
  margin-bottom: 30px !important;
}

/*-----------------
	64. Responsive
-----------------------*/

@media only screen and (min-width: 991px) {
  #toggle_btn {
    color: #999;
    /* float: left; */
    margin-left: 50px;
    font-size: 26px;
    line-height: 60px;
    padding: 0 10px;
  }

  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }
  .mini-sidebar .header .header-left {
    padding: 0 50px;
    width: 60px;
  }
  .mini-sidebar .sidebar {
    width: 60px;
  }
  .mini-sidebar.expand-menu .sidebar {
    width: 230px;
  }
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }
  .mini-sidebar .sidebar .noti-dot:before {
    display: none;
  }
  .mini-sidebar .sidebar .noti-dot:after {
    display: none;
  }
  .mini-sidebar.expand-menu .sidebar .noti-dot:before {
    display: block;
  }
  .mini-sidebar.expand-menu .sidebar .noti-dot:after {
    display: block;
  }
  .modal-open.mini-sidebar .sidebar {
    z-index: 1001;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span.chat-avatar-sm {
    display: inline-block;
    opacity: 1;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span.status {
    display: inline-block;
    opacity: 1;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
  .mini-sidebar .sidebar .sidebar-menu > ul > li > a i {
    font-size: 30px;
    width: 30px;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
    font-size: 24px;
    width: 20px;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 768px) {
  .modal-md {
    width: 600px;
  }
  .nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs.nav-justified.nav-tabs-top > li > a,
  .nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
  .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
  }
  .nav-tabs.nav-tabs-top > li {
    margin-bottom: 0;
  }
  .nav-tabs.nav-tabs-top > li > a,
  .nav-tabs.nav-tabs-top > li > a:hover,
  .nav-tabs.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
  }
  .nav-tabs.nav-tabs-top > li.open > a,
  .nav-tabs.nav-tabs-top > li > a:hover,
  .nav-tabs.nav-tabs-top > li > a:focus {
    border-top-color: #ddd;
  }
  .nav-tabs.nav-tabs-top > li + li > a {
    margin-left: 1px;
  }
  .nav-tabs.nav-tabs-top > li > a.active,
  .nav-tabs.nav-tabs-top > li > a.active:hover,
  .nav-tabs.nav-tabs-top > li > a.active:focus {
    border-top-color: #ff9b44;
  }
  .nav-tabs.nav-tabs-bottom > li {
    margin-bottom: -1px;
  }
  .nav-tabs.nav-tabs-bottom > li > a.active,
  .nav-tabs.nav-tabs-bottom > li > a.active:hover,
  .nav-tabs.nav-tabs-bottom > li > a.active:focus {
    border-bottom-width: 2px;
    border-color: transparent;
    border-bottom-color: #ff9b44;
    background-color: transparent;
    transition: none 0s ease 0s;
    -moz-transition: none 0s ease 0s;
    -o-transition: none 0s ease 0s;
    -ms-transition: none 0s ease 0s;
    -webkit-transition: none 0s ease 0s;
  }
  .nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
  }
  .nav-tabs.nav-tabs-solid > li {
    margin-bottom: 0;
  }
  .nav-tabs.nav-tabs-solid > li > a {
    border-color: transparent;
  }
  .nav-tabs.nav-tabs-solid > li > a:hover,
  .nav-tabs.nav-tabs-solid > li > a:focus {
    background-color: #f5f5f5;
  }
  .nav-tabs.nav-tabs-solid > .open:not(.active) > a {
    background-color: #f5f5f5;
    border-color: transparent;
  }
  .nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs-justified.nav-tabs-top > li > a,
  .nav-tabs-justified.nav-tabs-top > li > a:hover,
  .nav-tabs-justified.nav-tabs-top > li > a:focus {
    border-width: 2px 0 0 0;
  }
}
@media only screen and (max-width: 1199.98px) {
  .page-title-box {
    display: none;
  }
  .file-wrap .file-sidebar {
    left: -331px;
  }
  .file-wrap .file-cont-wrap {
    margin-left: 0;
  }
  .file-wrap.file-sidebar-toggle .file-sidebar {
    left: 0;
  }
  .file-wrap.file-sidebar-toggle .file-cont-wrap {
    margin-left: 0;
  }
  .file-side-close {
    display: inline-block;
  }
}
@media only screen and (max-width: 991.98px) {
  .profile-rightbar {
    display: inline-block !important;
  }
  .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .task-right-sidebar .task-chat-view {
    display: block;
    position: fixed;
    right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin-right: 0;
  }
  .task-chat-view {
    display: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px;
    position: fixed;
    transition: all 0.4s ease 0s;
    margin-right: -300px;
    display: table-cell;
    top: 0;
    padding-bottom: 60px;
    margin-top: 60px;
  }
  .chat-profile-view {
    display: none;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px;
    position: fixed;
    transition: all 0.4s ease 0s;
    margin-right: -300px;
    display: table-cell;
    top: 0;
    padding-bottom: 60px;
    margin-top: 60px;
  }
  .message-view.task-view {
    width: 100%;
  }
  .float-left.ticket-view-details {
    width: 90%;
  }
  .chat-main-row {
    left: 0;
  }
  .header .header-left {
    position: absolute;
    /* width: 100%; */
  }
  .mobile_btn {
    display: block;
  }
  #rotate {
    display: none;
  }

  .logout-btn-mob {
    display: block;
  }

  .logout-btn {
    display: none;
  }

  .page-title-box {
    display: none;
  }
  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  a.mobile_btn {
    color: #333;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 10;
  }
  #toggle_btn {
    display: none;
  }
  .page-wrapper {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .responsive-search {
    display: inline-block;
  }
  .top-nav-search form {
    display: none;
  }
  .top-nav-search .form-control {
    background-color: #fff;
    border-color: #e3e3e3;
    color: #333;
  }
  .top-nav-search .btn {
    color: #999;
  }
  .top-nav-search .form-control::-webkit-input-placeholder {
    color: #999;
  }
  .top-nav-search .form-control::-moz-placeholder {
    color: #999;
  }
  .top-nav-search .form-control:-ms-input-placeholder {
    color: #999;
  }
  .top-nav-search .form-control::-ms-input-placeholder {
    color: #999;
  }
  .top-nav-search .form-control::placeholder {
    color: #999;
  }
  .header .has-arrow .dropdown-toggle > span:nth-child(2) {
    display: none;
  }
  .header .has-arrow .dropdown-toggle::after {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.875rem;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }
  h2,
  .h2 {
    font-size: 1.75rem;
  }
  h3,
  .h3 {
    font-size: 1.375rem;
  }
  h4,
  .h4 {
    font-size: 1rem;
  }
  h5,
  .h5 {
    font-size: 0.9375rem;
  }
  h6,
  .h6 {
    font-size: 0.75rem;
  }
  .page-title,
  .dash-widget-info > h3,
  .account-title {
    font-size: 1.5rem;
  }
  .dash-card-content p,
  .stats-info h4,
  .account-box .account-btn,
  .job-title {
    font-size: 1.25rem;
  }
  .card-title,
  .dash-stats-list h4,
  .custom-modal .modal-title,
  .file-wrap .file-cont-wrap .file-cont-inner .file-cont-header > span,
  .faq-card .card .card-header h4 > a,
  .lead {
    font-size: 1.125rem;
  }
  .btn,
  .welcome-det p,
  .kanban-list > .kanban-header > .status-title,
  .account-box label,
  .job-info .job-dept,
  .form-focus .focus-label {
    font-size: 0.9375rem;
  }
  .onoffswitch-inner::before,
  .onoffswitch-inner::after,
  .form-control,
  .user-menu.nav > li > a,
  .page-header .breadcrumb {
    font-size: 0.875rem;
  }
  .btn-group-sm > .btn,
  .btn-sm {
    font-size: 0.8125rem;
  }
  .comp-section .section-title,
  .voice-call-avatar .username,
  .page-menu .nav-tabs > li > a,
  .btn-group-lg > .btn,
  .btn-lg,
  .account-subtitle,
  .job-list h3.job-list-title {
    font-size: 1rem;
  }
  .message-content {
    font-size: 0.75rem;
  }
  .user-menu.nav > li > a {
    padding: 0 10px;
  }
  .top-nav-search form {
    width: 210px;
  }
  .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }
  .notifications {
    right: -48px;
  }
  .notifications::before,
  .notifications::after {
    right: 60px;
  }
  .header {
    z-index: 1040;
  }
  .task-wrapper #task-list li .task-container .task-label {
    padding-right: 50px;
    word-break: unset;
  }
  .task-btn-right {
    position: absolute;
    right: 8px;
  }
  .task-wrapper #task-list li .task-container {
    padding: 8px 10px;
  }
  .task-action-btn.task-check {
    width: 36px;
  }
  .roles-menu {
    margin-bottom: 20px;
  }
  .contacts-list {
    padding: 0 70px 0 15px;
  }
  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }
  .right-action {
    text-align: center;
  }
  .top-action-left .float-left {
    float: none !important;
  }
  .top-action-left .btn-group {
    margin-bottom: 15px;
  }
  .top-action-right {
    text-align: center;
  }
  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }
  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }
  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs.nav-justified > li > a.active,
  .nav-tabs.nav-justified > li > a.active:hover,
  .nav-tabs.nav-justified > li > a.active:focus {
    border-color: transparent transparent transparent #ff9b44;
    border-left-width: 2px;
  }
  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .nav-tabs .nav-item {
    margin-bottom: 0;
  }
  .nav-tabs > li > a {
    border-width: 2px;
    border-left-color: transparent;
  }
  .nav-tabs .nav-link {
    border-width: 2px;
  }
  .nav-tabs > li > a:hover,
  .nav-tabs > li > a:focus {
    background-color: #fafafa;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs > li > a.active,
  .nav-tabs > li > a.active:hover,
  .nav-tabs > li > a.active:focus {
    background-color: #f5f5f5;
    border-color: transparent transparent transparent #ff9b44;
    border-left-width: 2px;
  }
  .nav-tabs > li.open:not(.active) > a,
  .nav-tabs > li.open:not(.active) > a:hover,
  .nav-tabs > li.open:not(.active) > a:focus {
    background-color: #fafafa;
  }
  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }
  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }
  .nav-tabs.nav-tabs-solid > li > a {
    border-left-width: 0 !important;
  }
  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
  }
  .nav-tabs-justified > li > a.active,
  .nav-tabs-justified > li > a.active:hover,
  .nav-tabs-justified > li > a.active:focus {
    border-width: 0 0 0 2px;
    border-left-color: #ff9b44;
  }
  .my-video ul li {
    width: 80px;
  }
  .voice-call-avatar .call-avatar {
    width: 120px;
    height: 120px;
  }
  .product-info {
    margin: 20px 0 30px;
  }
  .profile-bg {
    height: 280px;
  }
  .flag-nav > a > span {
    display: none;
  }
  .equal-height-cards > div > div.card {
    height: unset;
  }

  .chrtsdiv {
    border-right: none;
  }
}
@media only screen and (max-width: 575.98px) {
  .contact-cat {
    padding-right: 70px;
  }
  .user-menu {
    display: none;
  }
  .mobile-user-menu {
    display: block;
  }
  .search-box {
    display: none;
  }
  .filter-row {
    margin-bottom: 15px;
  }
  .page-wrapper > .content {
    padding: 15px;
  }
  .profile-view .profile-basic {
    margin-left: 0;
    padding-right: 0;
  }
  .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }
  .profile-info-left {
    text-align: center;
  }
  .profile-basic {
    margin-left: 0;
    margin-top: 15px;
  }
  .page-title {
    font-size: 18px;
  }
  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 200px;
    clear: both;
  }
  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 200px;
  }
  .upload-text {
    font-size: 18px;
  }
  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }
  .end-call {
    margin-top: 10px;
    position: inherit;
  }
  .chat-panel .chats {
    padding: 15px 0;
  }
  .chat-right .chat-content {
    max-width: 90%;
  }
  .chat-right .chat-body {
    padding-left: 0;
    padding-right: 0;
  }
  .chat-left .chat-content {
    max-width: 90%;
  }
  .chat-left .chat-body {
    padding-left: 0;
    padding-right: 0;
  }
  .chat-avatar .avatar {
    height: 20px;
    line-height: 20px;
    width: 20px;
  }
  .account-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .float-left.ticket-view-details {
    width: 80%;
  }
  .custom-menu > li > a {
    margin-left: 10px;
  }
  .contact-alphapets {
    top: 113px;
  }
  .account-page .main-wrapper {
    flex-direction: column;
  }
  .otp-input {
    font-size: 18px;
    height: 50px;
    margin-right: 10px;
    width: 52px;
  }
  .account-wrapper {
    padding: 15px;
  }
  .leave-right {
    flex: 0 0 auto;
  }
  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.85rem;
  }
  .call-box .call-avatar {
    height: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  .call-box .call-wrapper .call-items .call-item {
    padding: 10px;
  }
  .call-box .call-wrapper .call-items .call-item.call-end {
    margin: 30px 5px 0;
    padding: 10px;
  }
  .submit-section .submit-btn {
    margin: 0 0 10px !important;
  }
  .leave-row {
    display: block;
  }
  .leave-box .subtitle {
    display: none;
  }
  .leave-right {
    margin-top: 20px;
  }
  .leave-inline-form {
    display: block;
  }
  .page-header .breadcrumb {
    display: none;
  }
  .add-btn {
    font-size: 14px;
    min-width: inherit;
  }
}
/*Project Board*/
.page-header .page-title i {
  color: #e6e9ef;
}
.page-header .page-title i:hover,
.page-header .page-title i:active {
  color: #ffcb00;
}
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  border: 1px dashed #e6e9ef;
  outline: none;
  /*  padding:5px;*/
}
.page-header .text-truncate {
  width: 330px;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -1.25rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -1.75rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #2962ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
/*------Happy Zone------*/
.happyzone {
  /* background-image: url("../../assets/img/profiles/happy.svg"); */

  /* height: 100vh; */
  width: auto;
}
.happyzone-container {
  /* background-image: url("../../assets/img/profiles/happy.svg"); */
  width: auto;

  /* background-color: white;
  color: #007bff; */
  margin-top: 60px !important;
  background-repeat: no-repeat;
}

.video-heading {
  /* color: white; */
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 22px;
}
.video-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn.btn-primary {
  /* background-color: #007bff; */
  height: 40px;
  margin-top: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play-btn {
  background-color: #007bff;
  color: white;
  height: 40px;
}

/*dashboard*/
.feed-li {
  text-align: justify;
}
.profil-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 !important;
}
.post-item {
  background-color: #cccccc;
  font-size: 10px;
  padding: 4px 9px;
  border-radius: 5px;
}

/*calender*/
.ant-table table {
  font-size: 14px;
  font-weight: 600;
  color: #5f5f5f;
  font-family: Mulish, fira-sans-2, sans-serif;
}
.rectangle {
  margin-top: 3px;
  height: 12px;
  width: 12px;
  /* background-color: #000; */
}

.ctc-input {
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
}

.MuiCircularProgress {
  margin-right: 200px;
  width: 100px;
  height: 100px;
}
.MuiCircularProgress-root.MuiCircularProgress-colorPrimary.MuiCircularProgress-determinate {
  width: 80px !important;
  height: 80px !important;
}

.chrtsdiv {
  border-right: 1px dashed gray;
}
.transfer-div {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  min-height: 250px;
}
.ant-table {
  background-color: transparent !important;
}
.ant-table-thead .ant-table-cell {
  font-weight: 600 !important;
  color: gray;
}
tbody.ant-table-cell {
  font-weight: 500 !important;
  color: gray !important;
}
label {
  font-weight: bold;
}

.modal-open .container-fluid,
.modal-open .container {
  /* -webkit-filter: blur(0px) grayscale(100%); */
}
#retryButton {
  background-color: white !important;
  width: 50px !important;
  margin-top: -5px;
  margin-left: -10px;
}
.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  display: none;
}
div.fc-toolbar-chunk {
  display: flex;
}
.fc.fc-toolbar {
  justify-content: center !important;
}
.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  justify-content: center !important;
}
.fc-toolbar-chunk {
  margin-left: 5px;
  margin-right: 5px;
}
.fc-next-button.fc-button.fc-button-primary {
  padding: 2px !important;
}
.fc-prev-button.fc-button.fc-button-primary {
  padding: 2px !important;
}

.clock-in {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  height: 51px;
  margin-top: 5px;
  min-width: 120px;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 12px;
}
.clock_div {
  position: absolute;
  min-height: 200px;
  max-height: 500px;
  width: 400px;
  max-width: 400px;
  margin-top: 75px;
  margin-left: 37%;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
}

.clock-in.active {
  border: 1px solid white;
}
.work_mode_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.work_mode {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #faf2ff;
  border: 1px solid #973fcf;
  border-radius: 5px;
  width: 96.5px;
  height: 34px;
  margin: 4px;
}

.regular {
  background: #ed9d44;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 23px;
  width: 117px;
  height: 34px;
  color: white;
  margin-top: 30px;
  margin-right: 10px;
  border: none;
}

.regular_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clock-in-btn {
  background: #5ead56;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 23px 7px 12px;
  gap: 6px;
  width: 115.9px;
  height: 34.9px;
  color: white;
  border: none;
  margin-top: 30px;
  margin-left: 10px;
}

.break {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 23px 9px 22px;
  gap: 10px;
  color: white;
  border: none;
  width: 101px;
  height: 36px;
  background: #787486;
  border-radius: 5px;
  margin-top: 25px;
  margin-right: 10px;
}

.clock_out {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 23px 7px 12px;
  gap: 6px;
  border: none;
  width: 127.64px;
  height: 35.64px;
  color: white;
  background: #bd0000;
  border-radius: 5px;
  margin-top: 25px;
  margin-left: 10px;
}
.clock_out1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 23px 7px 12px;
  gap: 6px;
  border: none;
  width: 127.64px;
  height: 35.64px;
  color: white;
  background: #bd0000;
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 120px;
}

.work_time_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 70px;
  width: 210px;
  height: 72px;
  background: #ffffff;
  /* border: 1px solid #ed9d44; */
  padding: 10px;
}
.current_time {
  color: #60b158;
  font-size: 12px;
  margin-top: 10px;
}
.break_time {
  color: #ff0000;
  font-size: 12px;
  margin-top: 10px;
}
.current {
  margin-right: 10px;
  /* border-right: 1px solid #ed9d44; */
}

.break_div {
  margin-left: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #393f5c;
  border-radius: 5px;
  background-color: transparent;
  /* border-bottom-right-radius: 5px;
  border-top-right-radius: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
  width: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.progress-div {
  background-color: gray;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
}
.progress_div_fill {
  background-color: #007bff;
  height: 100%;
  /* width: 40%; */
  border-radius: 5px;
}

.smile {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 50px;
  align-items: end !important;
}
.smile-one {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 2px;
}

@media only screen and (max-width: 410px) {
  .smile {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
  }
}

/* .mood-smile:hover{
  width: 42px !important;
  height: 42px !important;
} */

.mood-smile {
  width: 25px;
  height: 25px;
}

@media only screen and (max-width: 991px) {
  .clock-in {
    margin-left: 50%;
  }
  .clock_div {
    margin-left: 20%;
  }
  #rotated {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* .clock-in{
    margin-left: 50%;
  } */
  .clock_div {
    margin-left: 0;
    width: 100%;
  }

  /* .logout-btn-mob {
    background: transparent;
    border: none;
    display: none;
  } */
}

.add-div {
  display: flex;

  justify-content: center;
}

.add-fields {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.add-btn-add {
  border: none;
  width: 100px;
  background-color: transparent;
  color: #009ce7;
  display: flex;
  justify-content: center;
}
.remove-div {
  display: flex;

  justify-content: end;
}
.cer-edu div:not(:last-child) {
  border-bottom: 1px solid grey;
}

.add-btn-remove {
  border: none;
  width: 20px;
  background-color: transparent;
  color: #009ce7;
  display: flex;
  justify-content: center;

  align-items: center;
}
.kpi-div {
  min-width: 240px;
  max-width: 300px;
  width: 242px;
}

.project-head {
  height: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-no-border {
  border: none !important;
}
.project-search {
  height: 39px;
  /* width: 400px; */
  border: none !important;
  padding-left: 10px;
  border-radius: 5px;
}
.search-btn {
  width: 100px;
  height: 39px !important;
  background-color: #00796b;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}

/* .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 0px;
}
.form-control:hover {
  border-bottom: 1px solid blue;
} */

.color-input {
  margin-top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.login-form {
  height: 100vh;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.card-board.ant-table-thead {
  display: none;
}
.css-1c87ro0 {
  /* width: 40px !important; */
  height: 40px !important;
  padding-left: 10px !important;
  padding-right: 10px !important ;
  /* pointer-events: none !important; */
}
/* .css-1l08w87 {
  height: 30px !important;
}
.css-1dlapeh {
  height: 30!px !important;
} */
.css-5mekwu {
  color: #000 !important;
  width: 100px !important;
  z-index: 1000000 !important;
}

.close-btn {
  border: none;
  background: transparent;
  position: absolute;
  margin-left: 90%;
}

.submit-btn1 {
  font-size: 16px;
  padding: 8px 12px;
  background: #3278db;
  color: white;
  border: none;
  border-radius: 5px;
}
.submit-btn2 {
  font-size: 16px;
  padding: 6px 18px;
  background: #3278db;
  color: white;
  border: none;
  border-radius: 15px;
}

.video-desc {
  margin-top: 5px;
}
.input-height {
  height: 38px !important;
  border-radius: 5px;
  padding-left: 5px;
}

.footer12 {
  position: fixed;
  bottom: 0;
}

.add-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

label {
  font-weight: 700 !important;
  color: #344767 !important;
}
.input-add {
  background: transparent !important;
  /* border-bottom: 1px solid gray; */
  height: 37px;
  margin-bottom: 30px !important;
}

.heading-add {
  font-weight: 600px;
  margin-bottom: 30px;
}

/* .iframe-class{
  overflow: hidden !important;
} */

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}

input:invalid {
  border-bottom: red solid 1px;
}
.error {
  color: red;
  font-size: 12px;
  margin-top: -20px;
}

.error1 {
  color: red;
  font-size: 12px;
  margin-top: 8px;
}
.error2 {
  margin-top: -10px !important;
}

.sup {
  color: red;
  margin-left: 1px;
  margin-top: 30px !important;
  /* margin-bottom: -5px; */
  font-size: 15px;
  position: inherit;
}

.headerStyles {
  margin-bottom: 8;
  color: inherit;
  font-size: 20;
  font-style: inherit;
  font-weight: 500;
  letter-spacing: -0.008em;
  line-height: 1.2;
}

.containerStyles {
  padding: 40px 44px 36px;
  text-align: center;
}

.marginBottomStyles {
  margin-bottom: 40;
}

.view-btn {
  border: none !important;
  font-size: 12px;
  padding: 5px;
  background: transparent;
}
.more-div {
  padding: 5px;
  border-radius: 2px;
  justify-content: center;
}

.view-icon {
  height: 15px !important;
  width: 15px !important;
}
.css-dk2yag-ModalHeader {
  justify-content: center !important;
}

/* .status-dropdown{
  position: absolute;
  z-index: 2;
  background-color: #007bff;
  
  
} */

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
}

.css-177ic5c {
  box-shadow: none !important;
}

.custom-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.54);
}

.kpi-pay {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.kpi-pay {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #3298db !important;
  min-width: 40px !important;
  padding: 2px 6px !important;
}

.css-1qqsbb3-MuiButtonBase-root-MuiButton-root {
  background-color: #3298db !important;
  min-width: 40px !important;
  padding: 2px 6px !important;
}
.welcome-font {
  color: white;
  font-size: 40px !important;
  font-weight: 50;
  margin-top: 36px;
  max-width: 660px;
  font-family: "Kodchasan";
}
.welcome-header {
  color: white;
  margin-top: 12%;
  font-family: "Kodchasan";
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  line-height: 84px;
}
.welcome-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 45px;
  border: none;
  position: absolute;
  width: 190px;
  height: 45px;
  left: 35%;
  top: 75%;
  color: white;
  background: linear-gradient(90deg, #20a3af 21.51%, #05d7eb 62.85%);
  border-radius: 25px;
}

.dropdown-insets {
  inset: -100px auto auto -40px !important;
  position: absolute !important;
  margin: 0px !important;
  transform: translate(-140px, 31px) !important;
}
.show-more {
  align-items: center;
  justify-content: center;
  padding: 6px 5px;
  border: none;
  font-size: 14px;
  width: 100px;
  height: 30px;
  color: white;
  background: linear-gradient(90deg, #20a3af 21.51%, #05d7eb 62.85%);
  border-radius: 25px;
  margin-top: 14px;
}

.top-happy-div {
  margin-right: auto;
  margin-left: auto;
  height: 350px;
  max-height: 400px;
  display: block;
  justify-content: center;
  width: 80%;
  margin-top: 50px;
  padding-top: 100px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.3));

  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
}
.top-pantry-div {
  margin-right: auto;
  margin-left: auto;
  height: inherit;
  min-height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin-top: 50px;
  padding-top: 100px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.3));

  padding: 0px !important;
  background: #ffffff;
  border-radius: 10px;
}

.m-avatar {
  background-color: #fff;
  height: 45px;
  position: relative;
  width: 95px;
  margin-left: -20px;
}
.m-avatar1 {
  background-color: #fff;
  height: 45px;
  position: relative;
  width: 95px;
  margin-left: -100px;
}
.pantry-part {
  justify-content: end;
  align-items: end;
  margin-top: 90px;
}
.pantry-icon {
  margin-top: 70px;
}

.pantry-heading {
  width: 240px;
  background: #ed9d44;
  color: white;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  font-size: 22px;
}

.task-board-color .menu-row1 {
  display: flex;
  height: 145px;
  text-align: center;
  justify-content: space-around;
  align-items: end !important;
  position: relative;
}

.menu-row2 {
  text-align: center;
}
.pantry-item {
  margin-top: 15px;
}
.amount-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  margin-top: -8px;
}
.amount-style {
  font-size: 1rem;
  background: #fff2e9;
  font-weight: 700;
  padding: 0px 6px;
  /* padding-top: -5px !important; */
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.amount-button {
  border: none;
  background: #ed9d44;
  color: white;
  padding-bottom: 2px;
}
.welcome-bold {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-weight: 900;
  font-family: "Kodchasan", sans-serif !important;
}
.happyzone-page {
  left: 0;
  padding-top: 80px;
  position: relative;
  transition: all 0.2s ease-in-out;
  /* min-height: 528px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dash-info-list1 .dash-card1 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  color: #575757;
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 15px;
}
.scroll-div2 {
  border-radius: 10px;
  margin-top: 10px;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.explore-more {
  position: absolute;
  width: 220px;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  bottom: 36px;
  right: 43%;
}
@media only screen and (max-width: 791px) {
  .explore-more {
    position: absolute;
    width: 200px;
    display: flex;
    justify-content: center;
    margin: auto;
    text-align: center;
    bottom: 36px;
    right: 27%;
  }
}

@media only screen and (max-width: 410px) {
  .top-happy-div {
    margin-right: auto;
    margin-left: auto;
    height: 430px !important;
    max-height: 500px;
    display: block;
    justify-content: center;
    width: 350px !important;
    margin-top: 50px;
    padding-top: 100px;
    filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.3));

    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
  }
}

.css-14k2fsq-MuiButtonBase-root-MuiFab-root:hover {
  background: #ffa500 !important;
  color: white;
}

.back-happyzone {
  border: none;
  color: "white";
  border-radius: 25px;
  background: #ed9d44;
  display: flex;
  float: right;
  justify-content: flex-end !important;
  text-align: right;
  flex-grow: 1;
  padding: 5px 15px;
  font-size: 14px;
}
.pantry-back-happyzone {
  border: none;
  color: "white";
  border-radius: 25px;
  background: #ed9d44;
  display: flex;
  float: left;
  justify-content: flex-end !important;
  text-align: right;
  align-items: center;
  /* flex-grow: 1; */
  padding: 2px 10px;
  font-size: 16px;
  margin-right: 15px;
}

.note {
  transform: translate(-130px, 62px) !important;
}

/* tr:nth-child(even) {
  background-color: grey;
} */

.pay-comp {
  background-color: #bed97a !important;
}
.pay_to {
  background-color: #cee29a !important;
}
.pay_head {
  background-color: #add157 !important;
}

tr {
  border: 1px solid grey;
  background-color: whitesmoke;
}
.col-row {
  border-right: 1px solid grey;
}
.card-img-top1 {
  width: 100% !important;
  height: 145px !important;
  border-radius: 15px 15px 0px 0px !important;
}
.react-player__preview {
  border-radius: 15px 15px 0px 0px !important;
}
.other {
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
  margin-left: 9%;
}
.other-r {
  /* margin-right: 8%; */
  padding-left: 8%;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

.table-action {
  display: flex;
  justify-content: space-around;
}
.salary_input {
  border-color: #009efb;
  height: 30px;
  border-radius: 5px;
  padding-left: 10px;
}
.salary_head {
  font-size: 16px;
  font-weight: 600;
}

.happyzone-play-btn {
  color: white !important;
  display: flex;
  border: none;
  background: #3ea6ff;
  padding: 0px 15px 0px 15px;
  border-radius: 30px;
  font-size: 14px;
  width: 100px;
  height: 30px;
  justify-content: center;
  text-align: center;
  position: relative;
  left: 60px;
  bottom: 0px !important;
  padding-top: 5px;
}
.error-back-btn {
  margin: 4%;
  border: none;
  border-radius: 10px;
  background: #f5707a;
  color: white;
  width: 120px;
  padding: 4px 10px;
}
.add-transfer-btn {
  background: #2db8c5;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  /* margin-top: 50px; */
  height: 30px;
  border-radius: 10px;
  color: white;
  width: 100px;
}
.main-page {
  background-color: white;
  padding: 50px;
  border-radius: 15px;
}
.tax-input {
  border: 1px solid black !important;
  float: right;
  height: 40px;
  border-radius: 5px;
  background-color: white;
}

.tax-input1 {
  border: 1px solid black !important;
  /* float: right; */
  height: 40px;
  border-radius: 5px;
  background-color: white;
}
.head_div {
  height: 50px;
  background-color: #009ce7;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.contain {
  background-color: #ebf4fd;
  padding: 1.5rem;
  border: 1px solid rgba(7, 107, 207, 0.18);
  border-radius: 10px;
  box-shadow: 5px solid #64b5f6;
}

.tax-btn {
  background-color: #009ce7;
  color: white !important;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.old-btn {
  background-color: #009ce7;
  color: white !important;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  white-space: nowrap;
}
.old1-btn {
  background-color: white;
  color: black !important;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  white-space: nowrap;
}
.regime-btn {
  border: 1px solid grey;
  display: flex;
  width: 300px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  border-radius: 5px;
}

.duplicate-project {
  border: 1px solid grey;
  display: flex;
  width: 275px;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 2px;
  border-radius: 5px;
}

.duplicate-project1 {
  border: 1px solid grey;
  display: flex;
  width: 215px;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 2px;
  border-radius: 5px;
}

.rupee {
  height: 15px;
  width: 40px;
  border-right: 1px solid grey;
}
.tax-i {
  height: 34px;
  border: none;
  padding-left: 10px;
}

/* .modal:nth-of-type(even) {
  z-index: 100000052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 100000051 !important;
} */

.modal-submit-btn {
  background: #329fd1;
  width: 100px;
  height: 38px;
  border-radius: 15px;
  font-size: 16px;
  color: white;
  border: none;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: black !important;
  min-width: none !important;
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  padding: 0px !important;
}

/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f0f5de;
} */

.form-input {
  height: 30px;
}
.css-kgl1hu-MuiTypography-root {
  line-height: normal !important;
}
.css-10qguk5-MuiTypography-root {
  line-height: normal !important;
}
.css-1mvpnfz {
  line-height: normal !important;
}
.css-13zje0k {
  line-height: normal !important;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 40px !important;
  padding-left: 40px !important;
}

.css-1ujsas3 {
  color: black !important;
  min-width: none !important;
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  padding: 0px !important;
}
.right-bar {
  background-color: #353a55;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  height: 92vh;
  float: right;
}

.page-title1 {
  color: #89939f;
  font-size: 15px;
}

.ant-btn {
  background-color: #393f5c;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.reports-para {
  margin: 0;
}
/* .ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: green !important;
}

.ant-table-row {
  background-color: #000000 !important;
}

 td {
  background-color: #000000 !important;
} */

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}
.btn-payroll {
  padding: 10px 25px;
  margin-top: 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}
.btn-payroll-d {
  padding: 10px 25px;
  margin-top: 20px;
  border: none;
  cursor: not-allowed;
  background-color: grey;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}
.payroll-btn-div {
  display: flex;
  justify-content: center;
}
.table-row-light {
  background-color: white;
}
.table-row-dark {
  background-color: #f5f5f5;
}

.ant-table-tbody > tr > td {
  height: 7px !important;
  padding: 6px !important;
}
.ant-table {
  /* font-size: 9px; */
  font-weight: 700;
}
.check-item {
  margin-top: 5px;
}
.kpi-img-box {
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  opacity: 1;
  background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
}

.kpi-img {
  height: 24px;
  width: 24px;
}
.kpi-head {
  /* margin: 0px; */
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px !important;
  /* line-height: 1.5; */
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(103, 116, 142);
  font-weight: 700;
}

.kpi-value {
  margin: 0px;
  font-size: 1.25rem;
  line-height: 1.375;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 700;
}
.css-h2ilq1-MuiTableCell-root {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.css-e784if-MuiTypography-root {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.css-1ccq9nq-MuiTableCell-root {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.css-1ypfif-MuiTypography-root {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.com_text {
  margin-top: 5px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(130, 214, 22);
  font-weight: 700;
}

.com_text1 {
  margin-top: 5px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(234, 6, 6);
  font-weight: 700;
}
.css-1p4kl0a {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.css-1m3krt3 {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.css-62dh81 {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}
.css-68o8xu {
  font-size: 0.73rem !important;
  font-weight: 500 !important;
}

.dash-card-cb {
  position: relative;
  overflow-y: auto;
  height: 180px;
}
.chat-bot-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.chat-bot-input {
  width: 90%;
  height: 40px;
  border: 2px solid #c4c4c4;
  border-radius: 4px;
  margin: auto;
}
.chat-bot-btn {
  background: #2db8c5;
  border-radius: 4px;
  border: none;
  text-align: center;
  padding: 6px 10px 6px 5px;
}
.user-message {
  float: flex-end !important;
  text-align: end !important;
  align-self: flex-end;
  margin-right: 25px;
  padding: 5px 12px;
  /* border: 2px solid #c4c4c4; */
  border-radius: 7px 7px 0px 7px;
  width: max-content;
  background: #2db8c5;
  color: #ffffff;

  /* margin-right: -300px !important;  */
}
.bot-message {
  text-align: start !important;
  background: #82ccdd;
  color: #ffffff;
  margin-left: 31px;
  padding: 5px 12px;
  /* border: 2px solid #c4c4c4; */
  border-radius: 7px 7px 0px 7px;
  width: max-content;
}
.message-list {
  overflow-y: scroll;
  height: 120px;
  width: 100%;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}

.css-z9fk8f:hover {
  background: #ffa500 !important;
  color: white;
}
.user-response {
  text-align: end;
  margin-right: 45px;
  margin-top: 10px;
}

.bot-response {
  text-align: start;
  max-width: 350px;
  background: #e4e6eb;
  color: #000000;
  padding: 5px 10px;
  border-radius: 7px 7px 7px 0px;
  margin-left: 55px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.quote {
  font-family: "Gabriela" !important;
  font-size: 17px;
}

.round {
  margin-top: 10px;
}
.birt-div {
  background-image: url("../img/profiles/animation_500_lfw79k6c.gif") !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.p-btn {
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

.wish-div {
  background-image: url("../../assets/img/profiles/birthday-pop-up-bg.svg");
  height: 300px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wish-btn {
  border: none;
  background-color: #009ce7;
  color: white;
  padding: 10px;
  border-radius: 7px;
}
.text-one {
  width: 440px;
  font-weight: bold;
}
.css-1hv8oq8-MuiStepLabel-label {
  color: white !important;
}
.css-h2cmlr {
  color: white !important;
}

input[type="file"] {
  width: 250px;
  max-width: 75%;
  height: 42px;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #555;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  height: 30px;
  background: #0d45a5;
  /* padding: 10px 20px; */
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background: #0d45a5;
}
/* .document-status{
  display: flex;
  flex-direction: row;
  text-align: center;
} */

.card-body-document {
  background-color: transparent;
  padding: 5px;
  border-radius: 10px;
}
.add-emp-field {
  /* box-shadow: 3px 3px 5px grey; */
  border-radius: 0.25rem;
}
.permission-btn {
  border: none;
  background: #00796b;
  color: white;
  border-radius: 5px;
}
.pre-table {
  padding: 10px !important;
}
.permission-update-btn {
  border: none;
  border-radius: 5px;
  background: #3298db;
  color: white;
  padding: 3px 13px;
}
.css-2fdkz6.Mui-active {
  color: #fff !important;
  font-weight: 500;
}
.css-2fdkz6.Mui-completed {
  color: #fff !important;
  font-weight: 500;
}
.dropdown-menu.dropdown-menu-right.show {
  inset: -70px auto auto -50px !important;
}
.dropdown-menu.dropdown-menu.show {
  inset: -80px auto auto -20px !important;
}
.css-1pznujj-Stack {
  z-index: 0 !important;
}

.ant-table-cell-fix-left {
  z-index: 6 !important;
}

.ant-table-cell-fix-left {
  z-index: 6 !important;
}
.css-1hw9j7s {
  background-color: #3298db !important;
  min-width: 40px !important;
  padding: 2px 6px !important;
}

.cardone {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 240px;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin-bottom: 20px;
}
._WuQ0f {
  background-color: white !important;
}
._34SS0 {
  background-color: white !important;
}

.ViewContainer {
  list-style: none;
  -ms-box-orient: horizontal;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  align-items: center;
}

.Switch {
  margin: 4px 15px;
  font-size: 14px;
  /* font-family: "Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue"; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.Switch_Toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 5px;
  /* border-radius: 10px; */
}

.Switch_Toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .Slider {
  background-color: #2196f3;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .Slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.Button1 {
  background-color: #89939f !important;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 7px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.Button2 {
  background-color: #a020f0 !important;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 7px 16px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.btn-sub-ctcbased {
  border: 1px solid #3298db;
  border-radius: 5px;
  /* color: white; */
  margin: auto;
  margin-top: 40px;
  padding: 6px 38px;
}
.btn-sub-basicbased {
  border: 1px solid #3298db;
  border-radius: 5px;
  /* color: white; */
  margin: auto;
  margin-top: 30px;
  padding: 6px 38px;
}
.permissions-back {
  width: 100px;
  height: 80px;
  padding: 10px 15px;
  border-radius: 10px;
  background: #ffffff;
  color: #000000;
}
.roles-header {
  background: #f6f8fc;
  margin-top: 15px;
  border-radius: 8px;
  padding-bottom: 100px;
}

th,
td {
  /* padding: 5px 15px !important; */
  /* border: 1px solid black; */
}

/* thead{
  border-radius: 10px !important; 
} */

.css-wjj10o-control {
  background-color: #ebebeb !important;
  border: 1px solid #b4b3b1 !important;
}
.upload-photo {
  width: 359px !important;
  height: 30px !important;
  max-width: 100% !important;
  border: none !important;
}
.form-control:disabled,
.form-control[readonly] {
  border: 1px solid grey !important;
}
.table-container-pantry {
  width: 100%;
  min-height: 450px;
  height: max-content;
  /* overflow: scroll; */
}
.custom-table-pantry {
  width: 100%;
  border-collapse: separate;
  outline: 2px solid #c4c4c4;
  border-radius: 5px;
  padding: auto;
  /* height: 400px; */
}
.custom-table-pantry th,
.custom-table-pantry td {
  border: 0px solid #ddd;
  padding: 12px;
  text-align: center;
}

.custom-table-pantry th {
  border-bottom: 2px solid #c4c4c4;
  background-color: #ffffff;
  font-size: 16px;
}
.custom-table-pantry tr,
td {
  background-color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px dashed #c4c4c4 !important;
  padding: 8px;
}

/* .custom-table-pantry tr:nth-child(even) {
  background-color: #ffffff;
} */

@media print {
  body {
    /* border: 5px solid #00796b; */
    border-top: thick green !important;
    border-bottom: 20px solid #1d8055;
  }

  /* Add more styles as needed */
}

.regularize-part {
  width: "100px";
}

.file-input-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding-top: 5px;
}

.custom-file-input {
  font-size: 12px;
  cursor: pointer;
  padding: 1px;
  width: 60px;
  border: none !important;
  filter: alpha(opacity=0); /* For IE8 and earlier */
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.dialog-content {
  background: white;
  /* padding: 20px; */
  border-radius: 15px;
  width: 35%;
  min-height: 40%;
  height: max-content;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #555;
  z-index: 1000;
}
.dialog-content .dialog-header {
  width: 100%;
  height: 55px;
  background: #353a55;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  text-align: center;
  color: #fff;
  align-items: center;
}
.dialog-content .dialog-disclaimer {
  height: 35px;
  margin: 10px 30px 10px 30px;
  border-radius: 5px;
  background: #f5f5f5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}
.dialog-content .dialog-buttons {
  min-height: 140px;
  height: max-content;
  margin: 10px 50px 10px 50px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}
.dialog-buttons button {
  padding: 10px 16px;
  border: 2px solid #c4c4c4;
  border-radius: 4px;
  color: #666666;
  background: none;
  font-size: 16px;
  font-weight: 550;
  min-width: 130px;
}
.designations {
  margin-top: 90px !important;
}
.designation-content {
  height: max-content;
  margin: 10px 25px 10px 25px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.designation-content button {
  padding: 8px 12px;
  border: 2px solid #c4c4c4;
  border-radius: 4px;
  color: #666666;
  background: none;
  font-size: 13px;
  font-weight: 550;
  min-width: 130px;
}

.reimbursement-report th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.reimbursement-report td {
  border: 1px solid #dddddd;
  text-align: right;
  padding: 8px;
}

.custom-table-pantry {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-pantry th,
.custom-table-pantry td {
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-table-pantry th {
  background-color: #f2f2f2;
  text-align: left;
}

.custom-table-pantry tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table-pantry tr:hover {
  background-color: #ddd;
}
