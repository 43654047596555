.choose-file-btn{
    /* background: #20A3AF; */
    padding: 3px 4px 10px;
   min-width: 100px;
    max-width: 131px;
height: 25px;
font-size: 14px;
border: none;
color: white;
border-radius: 25px;
}